import { TreeNode } from "./chart.types";

export class TreeChartClass {
  data: TreeNode;
  defaultNodeStyles = {
    color: "#F0F0F0",
    opacity: 1,
  };

  opacityNodeStyles = {
    ...this.defaultNodeStyles,
    opacity: 0.1,
  };

  firstGradient = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: "#86B5E9" },
      { offset: 1, color: "#093567" },
    ],
  };

  firstLastLevelStyles = {
    color: "rgba(20, 20, 20, 0.01)",
    borderColor: "#5295E0",
    borderWidth: 1,
    shadowColor: "#5295E0",
    shadowBlur: 8,
  };

  middleLevelStyles = {
    color: "#86B5E9",
    borderColor: "#86B5E9",
    borderWidth: 1,
    shadowColor: "#86B5E9",
    shadowBlur: 8,
  };

  lastGradient = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: "#141414" },
      { offset: 1, color: "#4775A9" },
    ],
  };

  constructor(data: TreeNode) {
    this.data = data;
    this.applyGradientToNodes(this.data.children);
  }

  applyGradientToNodes(nodes: TreeNode[], level = 0) {
    nodes.forEach((node) => {
      node.lineStyle = { color: this.firstGradient };
      node.itemStyle = this.middleLevelStyles;

      if (!node.children || node.children.length === 0) {
        node.lineStyle = { color: this.lastGradient };
        node.itemStyle = this.firstLastLevelStyles;
      }

      if (node.children && node.children.length > 0) {
        this.applyGradientToNodes(node.children, level + 1);
      }
    });
  }

  updateTreeNode(
    nodes: TreeNode[],
    activeNodeName: string,
    isParentActive = false,
  ): TreeNode[] {
    return nodes.map((node) => {
      const isActive = node.name === activeNodeName || isParentActive;

      node.lineStyle = isParentActive
        ? { ...node.lineStyle, opacity: 1 }
        : { ...node.lineStyle, opacity: 0.1 };

      node.itemStyle = isActive
        ? { ...node.itemStyle, opacity: 1 }
        : { ...node.itemStyle, opacity: 0.1 };

      if (node.children) {
        node.children = this.updateTreeNode(
          node.children,
          activeNodeName,
          isActive || isParentActive,
        );
      }

      return node;
    });
  }

  resetTreeNodeOpacity(nodes: TreeNode[]): TreeNode[] {
    return nodes.map((node) => {
      node.lineStyle = { ...node.lineStyle, opacity: 1 };
      node.itemStyle = { ...node.itemStyle, opacity: 1 };

      if (node.children) {
        node.children = this.resetTreeNodeOpacity(node.children);
      }

      return node;
    });
  }

  countTopChildren = (nodes: TreeNode[]): number => {
    let totalChildrenCount = 0;

    const countChildren = (node: TreeNode) => {
      if (node.children && node.children.length > 0) {
        node.children.forEach(countChildren);
      } else if (node.lineStyle.opacity === 1) {
        totalChildrenCount += 1;
      }
    };

    nodes.forEach(countChildren);

    return totalChildrenCount;
  };

  findNodePath(treeData: TreeNode, nodeName: string): string[] {
    let path: string[] = [];

    const findPath = (node: TreeNode, currentPath: string[]): boolean => {
      currentPath.push(node.name);

      if (node.name === nodeName) {
        path = [...currentPath];
        return true;
      }

      if (node.children) {
        for (const child of node.children) {
          if (findPath(child, currentPath)) {
            return true;
          }
        }
      }

      currentPath.pop();
      return false;
    };

    findPath(treeData, []);
    return path.length > 1 ? path.slice(1) : path;
  }

  getOption(data: TreeNode) {
    const options = {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        backgroundColor: "#333",
        borderColor: "#777",
        textStyle: { color: "#fff" },
      },
      series: [
        {
          type: "tree",
          orient: "RL",
          data: [data],
          left: "155px",
          right: "90px",
          top: "2%",
          bottom: "2%",
          symbol: "circle",
          symbolSize: 8,
          initialTreeDepth: 3,
          itemStyle: this.firstLastLevelStyles,
          label: {
            position: "right",
            align: "left",
            fontSize: 12,
            color: "#fff",
          },
          leaves: {
            label: {
              position: "left",
              align: "right",
              fontSize: 12,
              color: "#fff",
            },
          },
          emphasis: {
            focus: "descendant",
            itemStyle: { borderColor: "#ffcc00", borderWidth: 2 },
            lineStyle: { width: 2, color: "#ffcc00" },
            label: { fontWeight: "bold" },
          },
          focusNodeAdjacency: true,
          animationDurationUpdate: 750,
        },
      ],
    };
    return options;
  }
}
