import { Api } from "src/utils/api";
import { SALES_PERFORMANCE_API_BASE_URL } from "./sales-performance.constant";

export class SalesPerformanceApi extends Api {
  constructor() {
    super(SALES_PERFORMANCE_API_BASE_URL);
  }

  public getSpend = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("ad-spend", {
      date_start,
      date_end,
    });
  };

  public getSales = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("ad-sales", {
      date_start,
      date_end,
    });
  };

  public getClicks = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("clicks", {
      date_start,
      date_end,
    });
  };

  public getImpressions = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("impressions", {
      date_start,
      date_end,
    });
  };

  public getCtr = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("ctr", {
      date_start,
      date_end,
    });
  };

  public getCvr = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("cvr", {
      date_start,
      date_end,
    });
  };

  public getCpc = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("cpc", {
      date_start,
      date_end,
    });
  };

  public getAcos = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("acos", {
      date_start,
      date_end,
    });
  };

  public getRoas = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("roas", {
      date_start,
      date_end,
    });
  };

  public getUnitsSold = async (
    date_start: string,
    date_end: string,
  ): Promise<any> => {
    return await this.get("unit-sold", {
      date_start,
      date_end,
    });
  };
}
