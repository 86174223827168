export const data = [
  {
    name: "Contribution Profit",
    pv: 0,
    uv: 3750000,
    amt: 3750000,
    color: "#0077b6",
  },
  {
    name: "COGS",
    pv: 3750000,
    uv: -170000,
    amt: 3580000,
    color: "#FF6B6B",
  },
  {
    name: "Estimated Fees",
    pv: 3580000,
    uv: -340000,
    amt: 3240000,
    color: "#FF6B6B",
  },
  {
    name: "Ad Spend",
    pv: 3240000,
    uv: 300000,
    amt: 3540000,
    color: "#4CAF50",
  },
  {
    name: "Ad Sales",
    pv: 3540000,
    uv: 680000,
    amt: 4220000,
    color: "#4CAF50",
  },
  {
    name: "Organic Sales",
    pv: 4220000,
    uv: 710000,
    amt: 4930000,
    color: "#4CAF50",
  },
  {
    name: "Subscription Sales",
    pv: 4930000,
    uv: 35000,
    amt: 4965000,
    color: "#4CAF50",
  },
  {
    name: "What if Contribution Profit",
    pv: 0,
    uv: 4965000,
    amt: 4965000,
    color: "#0077b6",
  },
];
