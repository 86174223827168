import styles from "./styles.module.scss";
import { useProductStore } from "src/store/overviewProduct.state";
import ProductCard from "./ProductCard";
import { mockData } from "./mock";
import { OverviewType } from "@pages/Overview";

function Products({ type }: { type: OverviewType }) {
  const { selectedProduct, selectedProductToCompare } = useProductStore();
  return (
    <div className={styles.container}>
      <ProductCard
        product={selectedProduct}
        label="Your Product"
        selectType="your"
        mockData={mockData[0]}
        type={type}
      />

      <div className={styles.competitorStack}>
        {Array.isArray(selectedProductToCompare) &&
        selectedProductToCompare.length > 0 ? (
          selectedProductToCompare.map((product, index) => (
            <ProductCard
              key={index}
              productTwo={true}
              product={product}
              label={`Competitor ${index + 1}`}
              selectType="competitor"
              mockData={mockData[0]}
              type={type}
              showInput={index === 0}
            />
          ))
        ) : (
          <ProductCard
            productTwo={true}
            product={null}
            label="Competitor Product"
            selectType="competitor"
            mockData={mockData[0]}
            type={type}
          />
        )}
      </div>
    </div>
  );
}

export default Products;
