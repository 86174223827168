export const parseResponseText = (text: string): JSX.Element => {
  return (
    <>
      {text
        .slice(1, -1)
        .replace(/\\"/g, '"')
        .split("\\n")
        .map((line, index) => (
          <p key={index}>{line}</p>
        ))}
    </>
  );
};
