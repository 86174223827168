import classNames from "classnames";
import styles from "./styles.module.scss";
import { CSSProperties } from "react";

export type CheckWidgetData = {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  color: string;
};

interface CheckWidgetProps {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  isChecked: boolean;
  onChange: (value: string) => void;
  color: string;
}

interface CustomCSSProperties extends CSSProperties {
  "--checkbox-color"?: string;
}

const hexToRgba = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, 0.1)`;
};

export const getAdsLabel = (title: string) => {
  switch (title) {
    case "CPC":
      return null;
    case "ACOS":
      return null;
    case "ROAS":
      return null;
    default:
      return "Ads ";
  }
};

export const CheckWidget = ({
  title,
  value,
  increase,
  increaseDirection,
  isChecked,
  onChange,
  color,
}: CheckWidgetProps) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const listRef = useOutsideClick(() => {
  //   if (isOpen) {
  //     setIsOpen(false);
  //   }
  // });

  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: hexToRgba(color),
        borderColor: color,
      }}
    >
      <div className={styles.info}>
        <div className={styles.header}>
          <label
            className={classNames(
              styles.checkbox,
              isChecked ? styles.checked : "",
            )}
            style={
              {
                "--checkmark-color": isChecked ? color : "transparent",
              } as CustomCSSProperties
            }
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                if (title === "Orders") {
                  return;
                }
                onChange(title);
              }}
            />
            <span></span>
          </label>
          <h3>
            {getAdsLabel(title)}
            {title}
          </h3>
        </div>
        <div className={styles.value}>
          <span>{value}</span>
          <div
            className={classNames(
              styles.increase,
              increaseDirection === "up" ? styles.up : styles.down,
            )}
          >
            {increase}
          </div>
        </div>
      </div>
      {/* <button
        className={styles.arrow}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <BottomArrow />
      </button>
      {isOpen && (
        <ul className={styles.dropdown} ref={listRef}>
          <li>
            <button>% of orders New-to Brand (NTB)</button>
          </li>
          <li>
            <button>% of sales New-to Brand (NTB)</button>
          </li>
          <li>
            <button className={styles.purple}>
              Advertision cost of sale (ACOS)
            </button>
          </li>
          <li>
            <button className={styles.green}>Click-through rate (CTR)</button>
          </li>
          <li>
            <button>Clicks</button>
          </li>
          <li>
            <button>Cost Per 1,000 viewable impressions (VCPM)</button>
          </li>
          <li>
            <button className={styles.yellow}>Cost-per-click (CPC)</button>
          </li>
          <li>
            <button className={styles.indigo}>Impressions</button>
          </li>
          <li>
            <button className={styles.blue}>New-to-brand (NTB) orders</button>
          </li>
          <li>
            <button>New-to-brand (NTB) sales</button>
          </li>
        </ul>
      )} */}
    </div>
  );
};
