import { DraggableProvided } from "react-beautiful-dnd";
import { Select } from "antd";
import classNames from "classnames";
import styles from "./styles.module.scss";
import SectionContainer from "../components/SectionContainer";
import TopSectionIcons from "../components/TopSectionIcons";
import { compareOptions } from "./mock";
import { MetricCard } from "./MetricCard";
import React, { useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";
import { Tab } from "@pages/Dashboard/features/Widgets/Tab";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useWidgets } from "@hooks/useWidgets";
import { MAX_SELECTED_METRIC_BUILDERS } from "../../../constants";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";

type MetricBuilderProps = {
  handleRemove: () => void;
  provided?: DraggableProvided;
};

const MetricBuilder = ({ handleRemove, provided }: MetricBuilderProps) => {
  const { handleApply, updatedWidgets } = useWidgets();
  const { setWidgets } = useDashboardStore((state) => ({
    setWidgets: state.setWidgets,
    widgets: state.widgets,
  }));
  const [widgetList, setWidgetList] = useState<IWidget[]>([]);

  useEffect(() => {
    const selectedWidgets = updatedWidgets.slice(
      0,
      MAX_SELECTED_METRIC_BUILDERS,
    );
    setWidgetList(selectedWidgets);
    setWidgets(selectedWidgets);
  }, [updatedWidgets]);

  const onChangeMetrix = (name: "metric" | "compare", value: string) => {
    console.log(name, value);
  };

  return (
    <SectionContainer>
      <TopSectionIcons
        handleRemove={handleRemove}
        isContentClosed={false}
        provided={provided}
      />
      <div className={styles.filters}>
        <h3 className={styles.title}>Metric Builder</h3>
        <div className={styles.selects}>
          <Tab
            onApply={handleApply}
            maxSelectedWidgets={MAX_SELECTED_METRIC_BUILDERS}
            showTabIds={[0]}
            bgNearItem="var(--nyle-gray-20)"
          />
          <div className={styles.selectBox}>
            <Select
              className={classNames("custom-ant-select", styles.select)}
              popupClassName="custom-ant-select-dropdown"
              placeholder="Comparing"
              onChange={(value) => onChangeMetrix("compare", value)}
              options={compareOptions}
              suffixIcon={<ChevronDown />}
            />
          </div>
        </div>
      </div>
      <div className={styles.metricsGrid}>
        {widgetList.map((metric, i) => {
          return (
            <React.Fragment key={i}>
              <MetricCard metric={metric} />
              {i !== 0 && (i + 1) % 6 === 0 && (
                <div className={styles.metricsGridSeparator}>1</div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </SectionContainer>
  );
};

export default MetricBuilder;
