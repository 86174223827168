import { useEffect, useRef, useState } from "react";
import Tools from "./components/Tools";
import ProductDate from "./components/ProductDate";
import Sections from "./components/Sections";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";
import { SpiderService } from "@services/spider/spider.services";
import { useInsightsStore } from "src/store/insights/insights.state";
import { formattedKeys } from "src/utils/convertUtil";

function CROPage() {
  const [isActive, setIsActive] = useState(false);
  const sectionRefs = useRef({});
  const { selectedProduct } = useProductStore();
  const { setProductOneSpiderTitles, setProductOneSpiderValues } =
    useInsightsStore();

  useEffect(() => {
    const fetchProducts = async () => {
      if (!selectedProduct) return;

      try {
        await AmazonService.products.getPublicProduct(selectedProduct.asin);
        const spider = new SpiderService();
        const spiderDots = await spider.getSpiderInfo(selectedProduct.asin);

        setProductOneSpiderTitles(formattedKeys(spiderDots));
        setProductOneSpiderValues(
          Object.values(spiderDots).map((value) =>
            parseFloat(value.toFixed(1)),
          ),
        );
      } catch (err) {
        console.error("Error fetching product:", err);
      }
    };

    fetchProducts();
  }, [selectedProduct]);

  const scrollToSection = (area, sectionId) => {
    const key = `${area}-${sectionId}`;
    const sectionElement = sectionRefs.current[key];
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Tools
        scrollToSection={scrollToSection}
        isActive={isActive}
        setIsActive={setIsActive}
      />
      <div
        className={classNames(styles.content, { [styles.blurred]: isActive })}
      >
        <ProductDate />
        <Sections sectionRefs={sectionRefs} />
      </div>
    </>
  );
}

export default CROPage;
