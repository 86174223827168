import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import SectionContainer from "../components/SectionContainer";
import TopSectionIcons from "../components/TopSectionIcons";
import SwiperNavigation from "@components/ProductSwiper";
import { RadarChart } from "@components/Spider/RadarChart";
import DynamicComparisonBlock from "./ComparisonBlock";
import { DraggableProvided } from "react-beautiful-dnd";
import { useInsightsStore } from "src/store/insights/insights.state";
import { setRadarData } from "src/utils/setRadarData";

interface AspectOptimisationProps {
  handleRemove: () => void;
  provided?: DraggableProvided;
}

function AspectOptimisation({
  handleRemove,
  provided,
}: AspectOptimisationProps) {
  const { productOne } = useInsightsStore();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [currentDataSet, setCurrentDataSet] = useState(
    setRadarData(productOne.spiderValues),
  );
  const [_, forceUpdate] = useState(false);
  const [isNewData, setIsNewData] = useState(false);

  useEffect(() => {
    handleReset();
  }, [productOne.spiderValues]);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < productOne.spiderTitles.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : productOne.spiderTitles.length - 1,
    );
  };

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  const handleReset = () => {
    setCurrentDataSet(setRadarData(productOne.spiderValues));
    setIsNewData(false);
    setActiveIndex(0);
  };

  const emptyArray = useMemo(() => [], []);
  const [yourSet, competitorsSet] = currentDataSet;
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  return (
    <SectionContainer>
      <TopSectionIcons handleRemove={handleRemove} provided={provided} />
      <div className={styles.container}>
        <div className={styles.top}>
          <h3 className={styles.title}>Aspect optimization</h3>
          {isNewData && (
            <button className={styles.back} onClick={handleReset}>
              Back to original data
            </button>
          )}
        </div>
        <div className={styles.radarBox}>
          <RadarChart
            datasets={currentDataSet}
            activeIndex={activeIndex}
            onDotClick={handleDotClick}
            isDragEnabled={true}
            showTooltipWithArrow={isTooltipVisible}
            blockChanging={false}
            width={350}
            height={350}
            labelFontSize={11}
            update={() => {
              setIsNewData(true);
              forceUpdate((p) => !p);
            }}
            productOneValues={emptyArray}
            productTwoValues={emptyArray}
            productOneTitles={productOne.spiderTitles}
            productTwoTitles={productOne.spiderTitles}
          />
        </div>
        <div className={styles.headContent}>
          <div className={styles.swiperBox}>
            <SwiperNavigation
              headerText={productOne.spiderTitles[activeIndex]}
              onNext={handleNext}
              onPrev={handlePrev}
              showText={true}
              improvementValue={improvementValue}
            />
          </div>
          <DynamicComparisonBlock
            productScore={yourSet?.data[activeIndex] ?? 0}
            competitorScore={competitorsSet?.data[activeIndex] ?? 0}
          />
        </div>
      </div>
    </SectionContainer>
  );
}

export default AspectOptimisation;
