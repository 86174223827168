import { MetricData } from "@pages/Dashboard/store/math.state";

export const metricsData: MetricData[] = [
  { name: "Total Sales", value: 140035, difference: "-1%" },
  { name: "Orders", value: 3172439, difference: "-1%" },
  { name: "Units Sold", value: 140035, difference: "30%" },
  { name: "Net Profit", value: 3172439, difference: "40%" },
  { name: "Organic CTR", value: 140035, difference: "-2%" },
  { name: "Organic CVR", value: 3172439, difference: "20%" },
  { name: "Ad Impressions", value: 140035, difference: "-2%" },
  { name: "Ad Sales", value: 3172439, difference: "5%" },
  { name: "Ad Spend", value: 140035, difference: "-1%" },
  { name: "ACOS", value: 3172439, difference: "-4%" },
  { name: "Ad CTR", value: 140035, difference: "30%" },
  { name: "Ad CVR", value: 3172439, difference: "40%" },
];

export const metricOptions = [
  { value: "metric-1", label: "Metric 1" },
  { value: "metric-2", label: "Metric 2" },
  { value: "metric-3", label: "Metric 3" },
];
export const compareOptions = [
  { value: "compare-1", label: "Comparing to previous period" },
  { value: "compare-2", label: "Compare 2" },
  { value: "compare-3", label: "Compare 3" },
];
