import { useState } from "react";
import styles from "./styles.module.scss";
import { Navigation } from "./navigation";
import { CalendarModal } from "@components/calendar-modal";
import { formatDateRange } from "src/utils/formatDate";
import CalendarImage from "@assets/icons/calendar.svg";
import classNames from "classnames";
import CustomSelect from "./CustomSelect";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";

interface HeaderProps {
  setIsOpenMenu: (isOpen: boolean) => void;
}

export const Header = ({ setIsOpenMenu }: HeaderProps) => {
  const { dateRange, setDateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
  }));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title__container}>
        <Navigation setIsOpenMenu={setIsOpenMenu} />
        <h1 className={styles.title}>Ads Analytics</h1>
      </div>
      <div className={styles.buttons}>
        <div className={styles.calendar}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsCalendarOpen(!isCalendarOpen);
            }}
            className={classNames(
              styles.button,
              isCalendarOpen && styles.active,
            )}
          >
            <img src={CalendarImage} alt="Calendar" />
            {selectedDateRange}
          </button>
          {isCalendarOpen && (
            <div className={styles.modal}>
              <CalendarModal
                buttonWidth={148}
                closeModal={() => setIsCalendarOpen(false)}
                onDateChange={handleDateChange}
                initialRange={dateRange}
              />
            </div>
          )}
        </div>
        <CustomSelect dateRange={dateRange} />
      </div>
    </div>
  );
};
