export interface MetricData {
  date: number;
  [key: string]: number;
}

export const generateData = (
  startDate: number,
  endDate: number,
): MetricData[] => {
  const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
  const totalDays = Math.floor((endDate - startDate) / MILLISECONDS_IN_DAY);

  const data: MetricData[] = [];

  //   const startDateString = new Date(startDate).toISOString().split("T")[0];
  //   const endDateString = new Date(endDate).toISOString().split("T")[0];

  //   const widgetsData =  fetchWidgets(startDateString, endDateString);

  //   const totalSalesData = widgetsData[19];
  // console.log(totalSalesData,"totalSalesData");

  for (let i = 0; i <= totalDays; i++) {
    const date = startDate + i * MILLISECONDS_IN_DAY;

    const totalImpressions = Math.floor(Math.random() * 2_000_000) + 1_000_000;

    const adImpressions = Math.round(totalImpressions * 0.7);
    const organicImpressions = totalImpressions - adImpressions;

    const totalMetrics = generateMetrics("Total", totalImpressions);
    const adMetrics = generateMetrics("Ad", adImpressions);
    const organicMetrics = generateMetrics("Organic", organicImpressions);

    const subscriptionSales = Math.round(Math.random() * 1000 + 500);
    const ntbSales = Math.round(Math.random() * 1000 + 500);
    const contributionProfit = Math.round(Math.random() * 1000 + 500);
    // const totalSales = totalSalesData[i]

    data.push({
      date,
      ...totalMetrics,
      ...adMetrics,
      ...organicMetrics,
      SubscriptionSales: subscriptionSales,
      NtbSales: ntbSales,
      ContributionProfit: contributionProfit,
      // TotalSales: totalSales,
    });
  }

  return data;
};

const generateMetrics = (prefix: string, impressions: number) => {
  const ctr = Math.random() * (0.012 - 0.008) + 0.008;
  const clicks = Math.round(impressions * ctr);

  const cvr = Math.random() * (0.052 - 0.038) + 0.038;
  const orders = Math.round(clicks * cvr);

  const unitsSold = Math.round(orders * (1 - (Math.random() * 0.02 + 0.05)));

  const sales = Math.round(unitsSold * (Math.random() * 2 + 29));

  const spend = Math.round(sales * 0.3);

  return {
    [`${prefix}Impressions`]: impressions,
    [`${prefix}CTR`]: ctr * 100,
    [`${prefix}Clicks`]: clicks,
    [`${prefix}CVR`]: cvr * 100,
    [`${prefix}Orders`]: orders,
    [`${prefix}UnitsSold`]: unitsSold,
    [`${prefix}Sales`]: sales,
    [`${prefix}Spend`]: spend,
  };
};

export interface Metric {
  name: string;
  key: string;
  checked: boolean;
}

export interface SelectedMetrics {
  sales?: boolean;
  spend?: boolean;
  impressions?: boolean;
  ctr?: boolean;
  cpr?: boolean;
  cpc?: boolean;
  acos?: boolean;
  roas?: boolean;
  orders?: boolean;
  ntborders?: boolean;
}

export const availableMetrics: Metric[] = [
  { name: "Total Impressions", key: "TotalImpressions", checked: false },
  { name: "Total CTR", key: "TotalCTR", checked: false },
  { name: "Total Clicks", key: "TotalClicks", checked: false },
  { name: "Total CVR", key: "TotalCVR", checked: false },
  { name: "Total Orders", key: "TotalOrders", checked: false },
  { name: "Total Units Sold", key: "TotalUnitsSold", checked: false },
  { name: "Total Sales", key: "TotalSales", checked: true },
  { name: "Total Spend", key: "TotalSpend", checked: true },
  { name: "Ad Impressions", key: "AdImpressions", checked: false },
  { name: "Ad CTR", key: "AdCTR", checked: false },
  { name: "Ad Clicks", key: "AdClicks", checked: false },
  { name: "Ad CVR", key: "AdCVR", checked: false },
  { name: "Ad Orders", key: "AdOrders", checked: false },
  { name: "Ad Units Sold", key: "AdUnitsSold", checked: false },
  { name: "Ad Sales", key: "AdSales", checked: true },
  { name: "Ad Spend", key: "AdSpend", checked: true },
  { name: "Organic Impressions", key: "OrganicImpressions", checked: false },
  { name: "Organic CTR", key: "OrganicCTR", checked: false },
  { name: "Organic Clicks", key: "OrganicClicks", checked: false },
  { name: "Organic CVR", key: "OrganicCVR", checked: false },
  { name: "Organic Orders", key: "OrganicOrders", checked: false },
  { name: "Organic Units Sold", key: "OrganicUnitsSold", checked: false },
  { name: "Organic Sales", key: "OrganicSales", checked: true },
  { name: "Organic Spend", key: "OrganicSpend", checked: false },
  { name: "Subscription Sales", key: "SubscriptionSales", checked: false },
  { name: "NTB Sales", key: "NtbSales", checked: false },
  { name: "Contribution Profit", key: "ContributionProfit", checked: true },
];
