import React from "react";
import styles from "./styles.module.scss";

interface DynamicComparisonBlockProps {
  productScore: number;
  competitorScore: number;
  difference: string;
}

const DynamicComparisonBlock: React.FC<DynamicComparisonBlockProps> = ({
  productScore,
  competitorScore,
  difference,
}) => {
  const rootStyles = getComputedStyle(document.documentElement);
  const greenColor = rootStyles.getPropertyValue("--nyle-green").trim();
  const redColor = rootStyles.getPropertyValue("--nyle-red").trim();
  const determineScoreColor = (score1: number, score2: number): string => {
    if (score1 > score2) {
      return greenColor;
    } else if (score1 < score2) {
      return redColor;
    } else {
      return greenColor;
    }
  };

  const productScoreColor = determineScoreColor(productScore, competitorScore);
  const competitorScoreColor = determineScoreColor(
    competitorScore,
    productScore,
  );

  return (
    <div className={styles.comparisonBlock}>
      <div className={styles.scores}>
        <div className={styles.scoreBox}>
          <p>Score</p>
          <span
            className={styles.ratingValue}
            style={{ color: productScoreColor }}
          >
            {productScore}
          </span>
        </div>

        <div className={styles.difference}>
          <p>
            Your product score is{" "}
            <span className={styles.highlight}>{difference} below</span>{" "}
            selected competitor aspect score
          </p>
        </div>

        <div className={styles.scoreBox}>
          <p>Score</p>
          <span
            className={styles.ratingValue}
            style={{ color: competitorScoreColor }}
          >
            {competitorScore}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DynamicComparisonBlock;
