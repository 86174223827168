import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
} from "recharts";
import SectionContainer from "../components/SectionContainer";
import TopSectionIcons from "../components/TopSectionIcons";
import { data } from "./mock";
import { DraggableProvided } from "react-beautiful-dnd";

interface EventMapProps {
  handleRemove: () => void;
  provided?: DraggableProvided;
}

const EventMap: React.FC<EventMapProps> = ({ handleRemove, provided }) => {
  const [isContentHidden, setIsContentHidden] = useState(false);

  const contentHidden = () => {
    setIsContentHidden(!isContentHidden);
  };

  return (
    <SectionContainer>
      <div
        className={`${styles.container} ${isContentHidden ? styles.expanded : ""}`}
      >
        <TopSectionIcons
          handleRemove={handleRemove}
          contentHidden={contentHidden}
          isContentClosed={isContentHidden}
          provided={provided}
        />
        <h3 className={styles.title}>Event Map - Sales and CVR</h3>
        {!isContentHidden && (
          <div className={styles.infoContainer}>
            <div className={styles.infoItem}>
              <div className={styles.infoSupTitle}>Product</div>
              <div className={styles.infoSupTitle}>
                Sugarbear Hair Vitamins - B...
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.infoTitle}>Year Month</div>
              <div className={styles.infoSupTitle}>
                All 01.01.2023 31.01.2024
              </div>
            </div>
          </div>
        )}
        <ResponsiveContainer
          width={isContentHidden ? 600 : 305}
          height={isContentHidden ? 288 : 75}
        >
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#4e37b2" stopOpacity={0} />
              </linearGradient>
            </defs>
            {isContentHidden && (
              <CartesianGrid
                horizontal={true}
                vertical={false}
                strokeDasharray=""
              />
            )}
            {isContentHidden && (
              <XAxis
                dataKey="date"
                label={{ value: "Date", position: "insideBottomRight" }}
              />
            )}
            {isContentHidden && (
              <YAxis
                yAxisId="left"
                domain={[80000, 180000]}
                tick={{ className: styles.tick }}
                label={{ value: "Sales", angle: -90, position: "insideLeft" }}
              />
            )}
            {isContentHidden && (
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={[10, 50]}
                tick={{ className: styles.tick }}
                label={{ value: "CVR", angle: -90, position: "insideRight" }}
              />
            )}
            {isContentHidden && <Tooltip />}
            <Area
              yAxisId="left"
              type="monotone"
              dataKey="actualSales"
              stroke="#8884d8"
              fill="url(#colorGradient)"
              strokeWidth={2}
              dot={false}
              activeDot={false}
            />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="actualSales"
              stroke="#8884d8"
              strokeWidth={2}
              dot={false}
              activeDot={false}
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className={styles.legend}>
          <div className={styles.legendItem}>
            <span className={styles.actualSales}></span> Title 1
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default EventMap;
