import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useProductsStore } from "src/store/product.state";

const ProductDetails: React.FC = () => {
  const [showMore, setShowMore] = useState(false);
  const { productPublic } = useProductsStore();

  return (
    <div className={styles.productDetails}>
      <h1>{productPublic?.title}</h1>
      <div className={styles.itemDetails}>
        <p>About this item</p>
        <ul className={styles.detailsList}>
          <li>
            We designed our hair growth vitamins for those who were looking for
            easy to eat vitamins without the struggle of swallowing pills.
            Nourish your hair from within with our cruelty-free gummy bear hair
            vitamins.
          </li>
          <li>
            Scientifically formulated blend of Biotin, Vitamin D, Vitamin E,
            Vitamin B6, Folate, Calcium, Zinc, Sodium, Iodine and our hair
            growth proprietary blend with Inositol
          </li>
          <li>
            This certified vegan formula is based on clinical trials that have
            shown nutrients play an important role in hair health support. No
            gelatin. No dairy. No gluten. Kosher and Halal Certified.
          </li>
        </ul>
        {showMore && (
          <div className={styles.moreDetails}>
            <p>
              Gone are the days of dreading taking vitamins with our delicious
              and convenient gummies.
            </p>
          </div>
        )}
      </div>
      <button
        className={styles.showMoreButton}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default ProductDetails;
