import { useState } from "react";
import styles from "./styles.module.scss";
import { TabButton, TabsTypeList, viewTabs } from "./utils";
import { TypeTab, ViewTab, Chart } from "./Chart";
import { asinMock, impressionsMock } from "./mock";
import { Select } from "@components/Select";

export const SanKey = () => {
  const [selectedView, setSelectedView] = useState<ViewTab>("Tree");
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");
  const [asin, setAsin] = useState<string | null>(null);
  const [impressions, setImpressions] = useState<string | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.selectors}>
        <Select
          value={asin}
          placeholder="Select asin"
          options={asinMock}
          onChange={(value) => {
            setAsin(value);
          }}
          className={styles.asin}
        />
        <Select
          value={impressions}
          placeholder="Select impressions"
          options={impressionsMock}
          onChange={(value) => {
            setImpressions(value);
          }}
          className={styles.select}
        />
      </div>
      <div className={styles.header}>
        <h2>Sankey View</h2>
        <div className={styles.tabs}>
          {viewTabs.map((viewTab) => (
            <TabButton
              key={viewTab.label}
              label={viewTab.label}
              isSelected={selectedView === viewTab.label}
              onClick={() => setSelectedView(viewTab.label)}
              icon={viewTab.icon}
            />
          ))}
        </div>
      </div>
      <div className={styles.tabs}>
        <TabsTypeList
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <Chart type={selectedTab} view={selectedView} />
    </div>
  );
};
