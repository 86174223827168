import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import ProductInput from "@components/ProductInput";
import ProductCode from "@components/ProductCode";
import { useProductStore } from "src/store/overviewProduct.state";
import ProductSelectionModal from "../ProductSelectionModal";
import { Product } from "@services/amazon/products/types";
import { loadInsideProductData } from "src/utils/insides";
import { useInsightsStore } from "src/store/insights/insights.state";
import { SpiderService } from "@services/spider/spider.services";
import { formattedKeys } from "src/utils/convertUtil";
import { OverviewType } from "@pages/Overview";

const ProductInfo: React.FC<{ type: OverviewType }> = ({ type }) => {
  const { setSelectedProductToCompare } = useProductStore();
  const {
    setProductTwoHypotheses,
    setProductTwoPhrases,
    setProductTwoProblems,
    setProductTwoSpiderTitles,
    setProductTwoSpiderValues,
  } = useInsightsStore();
  const navigate = useNavigate();
  const spider = new SpiderService();
  const [isModalOpen, setModalOpen] = useState(false);

  const { selectedProductToCompare } = useProductStore();
  const openModal = () => setModalOpen(true);

  const handleProductSelect = async (selectedProductToCompare: Product) => {
    try {
      const { hypotheses, phrases, problems } = await loadInsideProductData(
        selectedProductToCompare,
      );

      const spiderDots = await spider.getSpiderInfo(
        selectedProductToCompare.asin,
        "show-room",
      );

      setProductTwoSpiderTitles(formattedKeys(spiderDots));
      setProductTwoSpiderValues(
        Object.values(spiderDots).map((value) => parseFloat(value.toFixed(1))),
      );
      setProductTwoHypotheses(hypotheses);
      setProductTwoPhrases(phrases);
      setProductTwoProblems(problems);

      setSelectedProductToCompare(selectedProductToCompare);
      setModalOpen(false);
      navigate(
        `/${type === "my-product" ? ROUTES.COMPARE : ROUTES.SHOW_ROOM_COMPARE}`,
      );
    } catch {
      console.log("errrorr");
    }
  };

  return (
    <>
      <div className={styles.productInfo}>
        <div className={styles.productDetails}>
          <div className={styles.productCode}>
            <ProductInput
              label={"Your product"}
              isProductSelected={false}
              type={type}
            />
          </div>
          <div className={styles.compareButtonBox}>
            <button className={styles.compareButton} onClick={openModal}>
              COMPARE
            </button>
          </div>
        </div>
        <div className={styles.code}>
          <ProductCode code={selectedProductToCompare?.asin ?? ""} />
        </div>
      </div>
      <ProductSelectionModal
        productTwo={true}
        isOpen={isModalOpen}
        onSelect={handleProductSelect}
        onClose={() => setModalOpen(false)}
        type={type}
      />
    </>
  );
};

export default ProductInfo;
