import { useOutsideClick } from "src/utils/useOutsideClick";
import styles from "./styles.module.scss";
import { useState } from "react";
import BottomArrow from "@assets/icons/bottomArrow";
import classNames from "classnames";

export type Option = {
  id: string;
  text: string;
  img?: string;
};

const renderOptions = (
  options: Option[] | string[] | React.ReactNode[],
  onChange,
) => {
  return options.map((option) => {
    if (option.img && option.id) {
      return (
        <button
          key={option.id}
          onClick={() => {
            onChange(option.id);
          }}
        >
          <img src={option.img} alt="product" />
          <div>
            <span>{option.id} </span>
            <span>{option.text}</span>
          </div>
        </button>
      );
    }

    return (
      <button
        key={option}
        onClick={() => {
          onChange(option);
        }}
      >
        {option}
      </button>
    );
  });
};

interface SelectProps {
  value: string;
  placeholder: string | React.ReactNode;
  options: Option[] | string[] | React.ReactNode[];
  onChange: (value: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
}

export const Select = ({
  value,
  placeholder,
  options,
  onChange,
  onOpen,
  onClose,
  className,
}: SelectProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useOutsideClick(() => {
    setOpen(false);
    onClose && onClose();
  });

  return (
    <button
      className={classNames(styles.selector, className, open && styles.open)}
      onClick={() => {
        setOpen(!open);
        if (!open) {
          onOpen && onOpen();
        }
      }}
      ref={selectRef}
    >
      <div className={classNames(styles.value, !value && styles.placeholder)}>
        {value ? value : placeholder}
      </div>
      <span className={styles.arrow}>
        <BottomArrow />
      </span>
      {open && (
        <div className={styles.options}>
          {options.length ? (
            renderOptions(options, onChange)
          ) : (
            <p>No options</p>
          )}
        </div>
      )}
    </button>
  );
};
