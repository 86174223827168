import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface DynamicComparisonBlockProps {
  productScore: number;
  competitorScore: number;
}

const DynamicComparisonBlock: React.FC<DynamicComparisonBlockProps> = ({
  productScore,
  competitorScore,
}) => {
  const difference = productScore - competitorScore;
  const state = difference > 0 ? "up" : "below";

  return (
    <div className={styles.comparisonBlock}>
      <div className={styles.scores}>
        <div className={styles.scoreBox}>
          <p>Your product score</p>
          <span
            className={classNames(
              styles.ratingValue,
              state === "up" ? styles.up : styles.below,
            )}
          >
            {productScore}
          </span>
        </div>

        <div
          className={classNames(
            styles.difference,
            state === "up" ? styles.up : styles.below,
          )}
        >
          <p>
            Your product score is{" "}
            <span className={styles.highlight}>
              {Math.abs(difference).toFixed(2)} {state}
            </span>{" "}
            selected competitor aspect score
          </p>
        </div>

        <div className={styles.scoreBox}>
          <p>Competitors average</p>
          <span className={styles.ratingValue}>{competitorScore}</span>
        </div>
      </div>
    </div>
  );
};

export default DynamicComparisonBlock;
