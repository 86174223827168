import { useState } from "react";

import styles from "./style.module.scss";
import GreenCheck from "@assets/icons/greenCheck";
import Trash from "@assets/icons/trash";
import classNames from "classnames";
import Star from "@assets/icons/StarKeyword";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { Keyword } from "src/store/keyword.state";

export const getStarColor = (index: number) => {
  switch (index) {
    case 0:
      return "#5295E0";
    case 1:
      return "#80C67A";
    case 2:
      return "#FE5858";
    case 3:
      return "#F7C137";

    case 4:
      return "#A5A5A5";
    case 5:
      return "#E6FF4B";
    case 6:
      return "#E667F1";
    case 7:
      return "#FFA500";

    default:
      return "#5295E0";
  }
};

interface KeyTooltipProps {
  close: () => void;
  keywordName: string;
  keyword: Keyword;
  setKeywords: (keywords: Keyword) => void;
}

export const KeyTooltip = ({
  close,
  keywordName,
  keyword,
  setKeywords,
}: KeyTooltipProps) => {
  const [inputValue, setInputValue] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState("");

  const tooltipRef = useOutsideClick(() => {
    close();
  }, "click");

  const handleAddKeyword = (value) => {
    if (value.trim()) {
      setKeywords({
        name: keywordName,
        keywords: [...keyword.keywords, value],
      });
      setInputValue("");
      setIsAdding(false);
    }
  };

  const handleDeleteKeyword = (index) => {
    const updatedKeywords = keyword.keywords.filter((_, i) => i !== index);
    setKeywords({
      name: keywordName,
      keywords: updatedKeywords,
    });
    if (editingIndex === index) {
      handleCancelEdit();
    }
  };

  const handleEditStart = (index) => {
    setEditingIndex(index);
    setEditingValue(keyword.keywords[index]);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditingValue("");
  };

  const handleSaveEdit = (index) => {
    const updatedKeywords = [...keyword.keywords];
    updatedKeywords[index] = editingValue;
    setKeywords({
      name: keywordName,
      keywords: updatedKeywords,
    });
    handleCancelEdit();
  };

  return (
    <div
      className={styles.container}
      ref={tooltipRef}
      style={{ pointerEvents: "auto" }}
      onClick={(e) => e.stopPropagation()}
    >
      {keyword &&
        keyword.keywords &&
        keyword.keywords.map((kw, index) => (
          <div key={index} className={styles.item}>
            <Star fill={getStarColor(index)} />
            <input
              className={styles.input__field}
              value={editingIndex === index ? editingValue : kw}
              onFocus={() => handleEditStart(index)}
              onChange={(e) => setEditingValue(e.target.value)}
              onBlur={() => {
                if (editingIndex === index) {
                  handleSaveEdit(index);
                }
              }}
            />
            {editingIndex === index && (
              <div className={styles.action__icons}>
                <button
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleSaveEdit(index);
                  }}
                >
                  <GreenCheck />
                </button>
                <button
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleDeleteKeyword(index);
                  }}
                >
                  <Trash />
                </button>
              </div>
            )}
          </div>
        ))}
      <div className={styles.item}>
        <Star />
        <input
          className={classNames(
            styles.input__field,
            keyword.keywords.length === 0 && styles.first,
          )}
          placeholder="Click to add custom keyword"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setIsAdding(true);
          }}
          onBlur={() => {
            setIsAdding(false);
            handleAddKeyword(inputValue);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddKeyword(inputValue);
            }
          }}
        />
        {isAdding && (
          <div className={styles.action__icons}>
            <button
              onMouseDown={(e) => {
                e.preventDefault();
                handleAddKeyword(inputValue);
              }}
            >
              <GreenCheck />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
