import { useState } from "react";
import styles from "./styles.module.scss";
import TopSectionIcons from "../components/TopSectionIcons";
import SectionContainer from "../components/SectionContainer";
import CustomInput from "@components/СustomInput";
import { DraggableProvided } from "react-beautiful-dnd";

interface PriceOptimisationProps {
  handleRemove: () => void;
  provided?: DraggableProvided;
}

const PriceOptimisation = ({
  handleRemove,
  provided,
}: PriceOptimisationProps) => {
  const [discount, setDiscount] = useState(-15);
  const [subscribeDiscount, setSubscribeDiscount] = useState(-5);
  const [buybox, setBuybox] = useState(0);

  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleSubscribeDiscountChange = (event) => {
    setSubscribeDiscount(event.target.value);
  };

  const handleBuyboxChange = (event) => {
    setBuybox(event.target.value);
  };

  return (
    <SectionContainer>
      <TopSectionIcons handleRemove={handleRemove} provided={provided} />
      <div className={styles.container}>
        <CustomInput
          label="Discount %"
          value={discount}
          onChange={handleDiscountChange}
        />
        <CustomInput
          label="Subscribe & Save discount %"
          value={subscribeDiscount}
          onChange={handleSubscribeDiscountChange}
        />
        <CustomInput
          label="BUYBOX %"
          value={buybox}
          onChange={handleBuyboxChange}
        />
      </div>
    </SectionContainer>
  );
};

export default PriceOptimisation;
