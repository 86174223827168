import { Table } from "antd";
import "./table.scss";
import classNames from "classnames";

interface ExpandableTableProps {
  columns: any[];
  data: any[];
  rowSelection?: any;
  className?: string;
  isLoading?: boolean;
}

const isCheckboxDisabled = (record) => {
  return (
    typeof record.campaign === "string" && record.campaign.startsWith("Total")
  );
};

export const ExpandableTable = ({
  columns,
  data,
  rowSelection,
  className,
  isLoading,
}: ExpandableTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isLoading}
      expandable={{
        expandRowByClick: true,
      }}
      rowSelection={
        rowSelection && {
          ...rowSelection,
          checkStrictly: false,
          getCheckboxProps: (record) => ({
            disabled: isCheckboxDisabled(record),
          }),
        }
      }
      scroll={{ x: 1000 }}
      pagination={false}
      className={classNames("expandable-table", className)}
      key={"key"}
    />
  );
};
