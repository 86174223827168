import React from "react";
import styles from "./styles.module.scss";
import MockStart from "@assets/img/stars.png";
import { ratings } from "@pages/Overview/mocks/ratings";
import { ReviewChart } from "./ReviewScore";
import { useProductStore } from "src/store/overviewProduct.state";

const ReviewScore: React.FC = () => {
  const { selectedProduct } = useProductStore();

  return (
    <div className={styles.reviewScoreContainer}>
      <div className={styles.productBox}>
        <div className={styles.imageContainer}>
          <img
            src={selectedProduct?.image_link ?? selectedProduct?.picture_url}
            alt="Product"
            className={styles.productImage}
          />
        </div>
        <div className={styles.ratingContainer}>
          <div className={styles.ratingWrapper}>
            <div className={styles.startBox}>
              <img src={MockStart} alt="Star" />
              <div className={styles.ratingValue}>3.3</div>
            </div>
            <div className={styles.ratingBreakdown}>
              {ratings.map((rating) => (
                <div key={rating.star} className={styles.ratingRow}>
                  <div className={styles.star}>{rating.star}</div>
                  <div className={styles.progressBar}>
                    <div style={{ width: rating.width }}></div>
                    <span>{rating.percentage}%</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartHeader}>
          <h3>Review score</h3>
        </div>
        <div>
          <ReviewChart />
        </div>
      </div>
    </div>
  );
};

export default ReviewScore;
