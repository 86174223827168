import { Api } from "src/utils/api";

import { ApiResponse } from "src/types/apiResponce";
import { SPIDER_BASE_URL } from "./spiderUrls";
import { OverviewType } from "@pages/Overview";

export class SpiderService extends Api {
  constructor() {
    super(SPIDER_BASE_URL);
  }

  public async getSpiderInfo(
    asin: string = "",
    type: OverviewType = "my-product",
  ): Promise<ApiResponse<any>> {
    const path =
      type === "my-product"
        ? "/get_product_spider_data/"
        : "/get_product_spiders_data_show_room/";

    return this.get(path, { asin });
  }

  public async getProfuctInfo(asin: string = ""): Promise<ApiResponse<any>> {
    return this.get(`/get_product_opportunities_list/`, { asin });
  }
  public async getProfuctSpiderSales(
    asin: string = "",
  ): Promise<ApiResponse<any>> {
    return this.get(`/get_product_spider_data_with_sales/`, { asin });
  }
  // public async getSpiderDataList(
  //   main_asin: string = "",
  //   ...asins: string[]
  // ): Promise<ApiResponse<any>> {
  //   // Створюємо параметри для ASIN без квадратних дужок
  //   const asinParams = asins
  //     .map((asin) => `asins=${encodeURIComponent(asin)}`) // виправлено назву параметра в map
  //     .join("&");

  //   // Формуємо рядок запиту
  //   const queryString = `main_asin=${encodeURIComponent(main_asin)}&${asinParams}`;

  //   // Повертаємо запит
  //   return this.get(`/get_product_spider_data_list_asins/?${queryString}`);
  // }

  public async getSpiderDataList(
    mainAsin: string,
    ...asins: string[]
  ): Promise<ApiResponse<any>> {
    const path = "/get_product_spider_data_list_asins/";

    const params = new URLSearchParams();

    asins.slice(0, 4).forEach((asin) => {
      params.append("asins", asin);
    });

    params.append("main_asin", mainAsin);

    const queryString = params.toString();

    return this.get(`${path}?${queryString}`);
  }
}
