import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { TreeNode, TooltipData } from "../chart.types";
import { mockTooltipData } from "./tooltip.const";
import { TreeChartClass } from "../tree-chart.class";
import { Tooltip } from "@components/Tooltip/tooltip";

interface TreeChartProps {
  data: TreeNode;
  isAdType?: boolean;
}

const getOffsetMultiplier = (childrenCount: number) => {
  if (childrenCount <= 5) {
    return 150 / childrenCount;
  }
  if (childrenCount <= 10) {
    return 35;
  }
  if (childrenCount <= 20) {
    return 15;
  }
  if (childrenCount <= 30) {
    return 15;
  }
  return 13;
};

export const TreeChart: React.FC<TreeChartProps> = ({ data, isAdType }) => {
  const chartInstance = new TreeChartClass(data);
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
  const [chartData, setChartData] = useState<TreeNode | null>(
    chartInstance.data,
  );
  const [tooltipPositionY, setTooltipPositionY] = useState(null);
  const [tooltipWithButton, setTooltipWithButton] = useState(false);
  const [tooltipWithImg, setTooltipWithImg] = useState(false);

  useEffect(() => {
    setChartData(() => {
      chartInstance.applyGradientToNodes(data.children);
      return data;
    });
  }, [data]);

  const clearStyles = () => {
    setChartData((prevState) => {
      const updatedData = chartInstance.resetTreeNodeOpacity(
        prevState.children,
      );
      return {
        name: prevState.name,
        itemStyle: { ...chartInstance.firstLastLevelStyles, opacity: 1 },
        children: updatedData,
      };
    });
    setTooltipData(null);
    setTooltipPositionY(null);
  };

  const onChartClick = (params: any) => {
    setTooltipWithButton(false);
    setTooltipWithImg(false);
    if (params.event.target.culling) {
      return;
    }
    if (params.data) {
      const tooltipName = chartInstance
        .findNodePath(chartData, params.name)
        .join(" -- ");

      const updatedData = {
        ...mockTooltipData,
        name: tooltipName,
      };
      setTooltipData(updatedData);

      const topChildrenCount = chartInstance.countTopChildren(
        chartInstance.updateTreeNode(chartData.children, params.name),
      );

      const rootOffsetY = topChildrenCount
        ? params.event.offsetY -
          topChildrenCount * getOffsetMultiplier(topChildrenCount)
        : params.event.offsetY - 150;

      setTooltipPositionY(rootOffsetY);

      if (params.event.target.style.text.startsWith("Nyl") && isAdType) {
        setTooltipWithImg(true);
      }

      if (params.event.target.style.textAlign === "right") {
        setTooltipWithButton(true);
      }

      setChartData((prevState) => {
        if (params.name === prevState.name) {
          return prevState;
        }

        const updatedData = chartInstance.updateTreeNode(
          prevState.children,
          params.name,
        );

        return {
          name: prevState.name,
          itemStyle: { ...chartInstance.firstLastLevelStyles, opacity: 0.1 },
          children: updatedData,
        };
      });
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ReactEcharts
        option={chartInstance.getOption(chartData)}
        style={{ height: "1140px", width: "100%" }}
        onEvents={{ click: onChartClick }}
      />
      {tooltipData && tooltipPositionY && (
        <Tooltip
          data={tooltipData}
          clearStyles={clearStyles}
          position={tooltipPositionY}
          withImg={tooltipWithImg}
          withButton={tooltipWithButton}
        />
      )}
    </div>
  );
};
