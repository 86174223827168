import UserPopupArrow from "@assets/icons/userPopupArrow";

export interface ProductItem {
  label: string;
  icon: React.ReactNode;
}

export const productItems: ProductItem[] = [
  {
    label: "My Products",
    icon: <UserPopupArrow />,
  },
  {
    label: "CRO Module",
    icon: <UserPopupArrow />,
  },
  {
    label: "Ads analytics",
    icon: <UserPopupArrow />,
  },
];
