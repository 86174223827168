import { TooltipData } from "./tooltip-types";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { CSSProperties } from "react";

export const RadarTooltip = ({
  data,
  isOpen,
  style,
}: {
  data: TooltipData;
  isOpen: boolean;
  style: CSSProperties;
}) => {
  const [yourProduct = { raw: 0 }, averageProduct = { raw: 0 }] =
    data.dataPoints;

  const yourProductRating = Number(yourProduct.raw);
  const averageProductRating = Number(averageProduct.raw);

  const difference = yourProductRating - averageProductRating;
  const status = difference > 0 ? "above" : "below";

  return (
    <div
      className={classNames(styles.tooltip, {
        [styles["tooltip--active"]]: isOpen,
      })}
      style={style}
    >
      <h4 className={styles.tooltipTitle}>{data.title}</h4>
      <div className={styles.tooltipItems}>
        {data.dataPoints.map((point, index) => {
          const isYourProduct = index === 0;
          return (
            <div
              key={index}
              className={styles.tooltipItem}
              style={{ borderColor: point.dataset.borderColor as string }}
            >
              <div>{point.dataset.label}</div>
              <div
                className={styles.tooltipItemPrice}
                style={{
                  color:
                    isYourProduct && status === "below" ? "#FE5858" : "#8AFF6C",
                }}
              >
                {point.formattedValue}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={styles.tooltipInfo}
        style={{
          color: status === "below" ? "#FE5858" : "#8AFF6C",
        }}
      >
        Your product is {Math.abs(difference).toFixed(2)} {status} average
      </div>
      <div className={styles.tooltipBottom}>
        <div>Expected sales after improving aspect</div>
        <div className={styles.tooltipPrice}>$1350.39</div>
      </div>
    </div>
  );
};
