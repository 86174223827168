import { ChatApi } from "@services/chat/chat.api";
import { useChatStore } from "@services/chat/chat.service";
import { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import Sparkle from "@assets/icons/sparkle";
import classNames from "classnames";
import ChatClose from "@assets/icons/chat-close";
import PaperPlane from "@assets/icons/paper-plane";
import Logo from "@assets/icons/logo";
import { Message } from "./message";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { ChatPrompts } from "./prompts";
import { MessageLoader } from "@components/ui-kit/MessageLoader";
import { parseResponseText } from "../utils";

export const Chat = () => {
  const chatApi = new ChatApi();
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [currentDate] = useState(
    new Date().toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }),
  );
  const { messages, addMessage } = useChatStore();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const chatRef = useOutsideClick(() => {
    setShowChat(false);
  });

  const sendMessage = async (value?: string) => {
    setIsLoading(true);
    if (!value && !input.trim()) return setIsLoading(false);

    if (!value) {
      addMessage({ id: new Date().toISOString(), text: input, sender: "user" });
      setShowPrompts(false);
    }
    setInput("");

    try {
      const response = await chatApi.sendMessage(value ?? input);
      const parsedText = parseResponseText(response.body);
      addMessage({
        id: new Date().toISOString(),
        text: parsedText,
        sender: "bot",
      });
    } catch (error) {
      addMessage({
        id: new Date().toISOString(),
        text: "Sorry, I can't answer that right now.",
        sender: "bot",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sendMessage("Hello");
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className={style.chat} ref={chatRef}>
      <div
        className={classNames(style.chat__container, {
          [style._active]: showChat,
        })}
      >
        <div className={style.chat__inner}>
          <div className={style.chat__body}>
            <div className={style.chat__header}>
              <div className={style.logo}>
                <Logo />
              </div>
              <div className={style.title}>
                <h2>Nyle AI Assistant</h2>
                <p>You can ask me anything you want!</p>
              </div>
            </div>
            <div className={style.chat__messages}>
              <span>Today, {currentDate}</span>
              {messages.map((msg) => (
                <Message key={msg.id} message={msg} />
              ))}
              {showPrompts && (
                <ChatPrompts
                  setShowPrompts={setShowPrompts}
                  addMessage={addMessage}
                  sendMessage={sendMessage}
                />
              )}
              {isLoading && (
                <div className={style.loader}>
                  <div className={style.logo}>
                    <Logo />
                  </div>
                  <MessageLoader />
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className={style.chat__input}>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              type="text"
              placeholder="Message..."
            />
            <button
              onClick={() => {
                sendMessage();
              }}
            >
              <PaperPlane />
            </button>
          </div>
        </div>
      </div>
      <button
        className={classNames(style.chat__button, {
          [style._active]: showChat,
        })}
        onClick={() => {
          setShowChat(!showChat);
        }}
      >
        {showChat ? <ChatClose /> : <Sparkle />}
      </button>
    </div>
  );
};
