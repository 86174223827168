import {
  ProductWithImageLink,
  ProductWithPictureUrl,
} from "@services/amazon/products/types";
import { create } from "zustand";

interface ProductsState {
  productsOwn: ProductWithImageLink[];
  productPublic: ProductWithPictureUrl | null;
  productsPublic: ProductWithPictureUrl[];
  setProductsOwn: (products: ProductWithImageLink[]) => void;
  setProductPublic: (product: ProductWithPictureUrl) => void;
  setProductsPublic: (products: ProductWithPictureUrl[]) => void;
}

export const useProductsStore = create<ProductsState>((set) => ({
  productsOwn: [],
  productPublic: null,
  productsPublic: [],

  setProductsOwn: (products) => set({ productsOwn: products }),
  setProductPublic: (product) => set({ productPublic: product }),
  setProductsPublic: (products) => set({ productsPublic: products }),
}));
