import { useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Legend } from "@pages/Dashboard/features/SalesPerformance/Legend";
import { Chart } from "@pages/Dashboard/features/SalesPerformance/Chart";
import MetricModal from "./MetricModal";
import {
  availableMetrics,
  Metric,
} from "@pages/Dashboard/mocks/mockMetricsData";

export const SalesPerformance = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [selectedMetrics, setSelectedMetrics] = useState<Metric[]>(
    availableMetrics.filter((metric) => metric.checked),
  );
  const [checks, setChecks] = useState({
    sales: true,
    spend: true,
    impressions: true,
    ctr: true,
    cpr: true,
    cpc: true,
    acos: true,
    roas: true,
    orders: true,
    ntborders: true,
    cvr: true,
    unitSolds: true,
    clicks: true,
  });

  const handleApplyMetrics = (metrics: Metric[]) => {
    setSelectedMetrics(metrics);
    setIsModalOpen(false);
  };

  return (
    <div className="sales-performance">
      <header className="sales-performance__header">
        <h3 className="sales-performance__title heading-2">
          {t("label.sales_performance")}
        </h3>
      </header>

      <div className="sales-performance__body">
        <Legend
          handleOpenModal={handleOpenModal}
          selectedMetrics={selectedMetrics}
        />
        <Chart checks={checks} />
        <MetricModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onApply={handleApplyMetrics}
        />
      </div>
    </div>
  );
};
