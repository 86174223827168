import { AddsSalesApi } from "@services/adds-sales/adds-sales.api";

export const generateWidgets = (data: {
  impressions?: number;
  sales?: number;
  spend?: number;
  ctr?: number;
  cvr?: number;
  cpc?: number;
  acos?: number;
  roas?: number;
  orders?: number;
  unitSolds?: number;
  clicks?: number;
}) => {
  return [
    {
      title: "Sales",
      value: `$${data.sales.toFixed(2)}`,
      increase: "+3.33%",
      increaseDirection: "up",
      color: "#80C67A",
    },
    {
      title: "Spend",
      value: `$${data.spend.toFixed(2)}`,
      increase: "-2.14%",
      increaseDirection: "down",
      color: "#BF4C46",
    },
    {
      title: "Impressions",
      value: data.impressions.toLocaleString(),
      increase: "-2.12%",
      increaseDirection: "down",
      color: "#5C55D5",
    },
    {
      title: "CTR",
      value: `${(data.ctr * 100).toFixed(2)}%`,
      increase: "+0.03%",
      increaseDirection: "up",
      color: "#67AC9B",
    },
    {
      title: "CVR",
      value: `${(data.cvr * 100).toFixed(2)}%`,
      increase: "+1.22%",
      increaseDirection: "up",
      color: "#9C8437",
    },
    {
      title: "CPC",
      value: `$${data.cpc.toFixed(2)}`,
      increase: "-0.10%",
      increaseDirection: "down",
      color: "#A8A03D",
    },
    {
      title: "ACOS",
      value: `${(data.acos * 100).toFixed(2)}%`,
      increase: "+3.01%",
      increaseDirection: "up",
      color: "#9E44CC",
    },
    {
      title: "ROAS",
      value: `${(data.roas * 100).toFixed(2)}%`,
      increase: "+0.50%",
      increaseDirection: "up",
      color: "#F19867",
    },
    {
      title: "Orders",
      value: data.orders.toLocaleString(),
      increase: "+12.11%",
      increaseDirection: "up",
      color: "#5295E0",
    },
    {
      title: "Units Sold",
      value: data.unitSolds.toLocaleString(),
      increase: "-0.98%",
      increaseDirection: "down",
      color: "#255FA0",
    },
  ];
};

export type WidgetData = {
  sales: number;
  spend: number;
  impressions: number;
  ctr: number;
  cvr: number;
  cpc: number;
  acos: number;
  roas: number;
  orders: number;
  unitSolds: number;
  clicks: number;
};

export const getWidgetData = async (dateRange: {
  startDate: Date;
  endDate: Date;
}): Promise<WidgetData> => {
  const { startDate, endDate } = dateRange;

  const addsSalesApi = new AddsSalesApi();
  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];

  const [
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    orders,
    unitSolds,
    clicks,
  ] = await Promise.all([
    addsSalesApi.getSales(startDateString, endDateString),
    addsSalesApi.getSpend(startDateString, endDateString),
    addsSalesApi.getImpressions(startDateString, endDateString),
    addsSalesApi.getCtr(startDateString, endDateString),
    addsSalesApi.getCvr(startDateString, endDateString),
    addsSalesApi.getCpc(startDateString, endDateString),
    addsSalesApi.getAcos(startDateString, endDateString),
    addsSalesApi.getRoas(startDateString, endDateString),
    addsSalesApi.getOrders(startDateString, endDateString),
    addsSalesApi.getUnitsSold(startDateString, endDateString),
    addsSalesApi.getClicks(startDateString, endDateString),
  ]);

  return {
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    orders,
    unitSolds,
    clicks,
  };
};
