import { create } from "zustand";

export interface Section {
  id: number;
  label: string;
  component: React.ReactNode;
}

interface SectionState {
  sectionsBefore: Section[];
  sectionsSide: Section[];
  sectionsAfter: Section[];
  addSectionBefore: (section: Section) => void;
  addSectionSide: (section: Section) => void;
  addSectionAfter: (section: Section) => void;
  removeSectionBefore: (id: number) => void;
  removeSectionSide: (id: number) => void;
  removeSectionAfter: (id: number) => void;
  reorderSections: (
    source: { droppableId: string; index: number },
    destination: { droppableId: string; index: number },
  ) => void;
}

export const useSectionStore = create<SectionState>((set) => ({
  sectionsBefore: [],
  sectionsSide: [],
  sectionsAfter: [],
  addSectionBefore: (section) =>
    set((state) => ({
      sectionsBefore: [...state.sectionsBefore, section],
    })),
  addSectionSide: (section) =>
    set((state) => ({
      sectionsSide: [...state.sectionsSide, section],
    })),
  addSectionAfter: (section) =>
    set((state) => ({
      sectionsAfter: [...state.sectionsAfter, section],
    })),
  removeSectionBefore: (id) =>
    set((state) => ({
      sectionsBefore: state.sectionsBefore.filter(
        (section) => section.id !== id,
      ),
    })),
  removeSectionSide: (id) =>
    set((state) => ({
      sectionsSide: state.sectionsSide.filter((section) => section.id !== id),
    })),
  removeSectionAfter: (id) =>
    set((state) => ({
      sectionsAfter: state.sectionsAfter.filter((section) => section.id !== id),
    })),
  reorderSections: (source, destination) =>
    set((state) => {
      const sectionsMap = {
        sectionsBefore: state.sectionsBefore,
        sectionsSide: state.sectionsSide,
        sectionsAfter: state.sectionsAfter,
      };

      const sourceList = sectionsMap[source.droppableId] ?? state.sectionsAfter;

      const destinationList =
        sectionsMap[destination.droppableId] ?? state.sectionsAfter;

      const [movedItem] = sourceList.splice(source.index, 1);
      destinationList.splice(destination.index, 0, movedItem);

      return {
        sectionsBefore: [...state.sectionsBefore],
        sectionsSide: [...state.sectionsSide],
        sectionsAfter: [...state.sectionsAfter],
      };
    }),
}));
