import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./styles.module.scss";
import ProductDetails from "./ProductDetails";
import ProductImage from "./ProductImage";
import ProductCard from "./ProductCard";
import ProductHeaderTitle from "./components/ProductHeaderTitle";
import VideoSection from "./VideoSection";
import Optimisation from "./Optimisation";

interface ProductDetailPage {
  sideSlot?: ReactNode;
}

const ProductDetailPage: React.FC<ProductDetailPage> = ({ sideSlot }) => {
  return (
    <div className={styles.productDetaiBox}>
      <ProductCard />
      <div className={styles.productDetailPageWrapper}>
        <ProductHeaderTitle title="Product detail page" />
        <div className={styles.productDetailPage}>
          <div className={styles.imageAndDetails}>
            <ProductImage />
            <ProductDetails />
          </div>
          <VideoSection />
          <div className={styles.aPlusContent}>
            <h2>A+ Content</h2>
          </div>
        </div>
      </div>
      <div className={styles.optimisation}>
        <Optimisation />
        {sideSlot}
      </div>
    </div>
  );
};

export default ProductDetailPage;
