import React, { useState } from "react";
import styles from "./styles.module.scss";
import SuccessArrow from "@assets/icons/SuccessArrow";
import FallArrow from "@assets/icons/FallArrow";
import classNames from "classnames";
import IconStar from "@assets/icons/iconStar";

interface Problem {
  title_problem: string;
  problem_description: string;
  solution_title: string;
  solution_description: string;
}

interface ProblemSolutionProps {
  problems: Problem[];
  activeProblemIndex: number;
  onPredictionInsightClick: () => void;
}

const ProblemSolution: React.FC<ProblemSolutionProps> = ({
  problems,
  activeProblemIndex,
  onPredictionInsightClick,
}) => {
  const currentProblem = problems[activeProblemIndex];
  const [predictActive, setPredictActive] = useState(false);

  const onPredictClick = () => {
    setPredictActive(!predictActive);
    onPredictionInsightClick();
  };

  return (
    <div className={styles.problemSolution}>
      <div className={styles.problemSection}>
        <h4 className={styles.typography}>Problem</h4>
        <div className={styles.between}>
          <FallArrow />
          <h2 className={styles.typographyLight}>
            {currentProblem?.title_problem}
          </h2>
        </div>
        <p className={styles.typographySmall}>
          {currentProblem?.problem_description}
        </p>
      </div>
      <div className={styles.solutionSection}>
        <h4 className={styles.typography}>Solution</h4>
        <div className={styles.between}>
          <SuccessArrow />
          <h2 className={styles.typographyLight}>
            {currentProblem?.solution_title}
          </h2>
        </div>
        <p className={styles.typographySmall}>
          {currentProblem?.solution_description}
        </p>
        {predictActive ? (
          <button
            className={classNames(
              styles.predictionButton,
              styles.predictionButtonActive,
            )}
            onClick={onPredictClick}
          >
            BACK TO ORIGINAL DATA
          </button>
        ) : (
          <button className={styles.predictionButton} onClick={onPredictClick}>
            <IconStar /> NYLE PREDICTION INSIGHT
          </button>
        )}
      </div>
    </div>
  );
};

export default ProblemSolution;
