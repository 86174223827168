export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100% 100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g width="100%" height="100%" clip-path="url(#clip0_850_40892)">
        <g clip-path="url(#clip1_850_40892)">
          <path
            d="M0 113.637L1259 113.637"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 228.273L1259 228.273"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 342.91L1259 342.91"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 457.545L1259 457.545"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 572.182L1259 572.182"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 686.818L1259 686.818"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 801.455L1259 801.455"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="1.20344"
          />
          <path
            d="M0 916.092L1256 916.092"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="2.40688"
          />
          <path
            d="M0 1030.73L1256 1030.73"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="2.40688"
          />
          <path
            d="M0 1145.36L1256 1145.36"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="2.40688"
          />
          <path
            d="M0 1260L1256 1260"
            stroke="white"
            stroke-opacity="0.05"
            stroke-width="2.40688"
          />
        </g>
        <rect
          x="0.601719"
          y="-0.398281"
          width="1254.8"
          height="1259.8"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M0 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M139.777 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M279.555 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M419.332 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M559.109 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M698.891 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M838.668 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M978.445 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M1118.22 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M1258 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M978.445 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M1118.22 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
        <path
          d="M1258 0V1260"
          stroke="white"
          stroke-opacity="0.05"
          stroke-width="1.20344"
        />
      </g>
      <defs>
        <clipPath id="clip0_850_40892">
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
        <clipPath id="clip1_850_40892">
          <rect y="-1" width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
