import React, { useEffect } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import toolsData from "./mock";
import { useSectionStore } from "src/store/croModule.state";
import TotalOptimization from "../../Sections/TotalOptimization";
import OptimisationDetails from "../../Sections/OptimisationDetails";
import AspectOptimisation from "../../Sections/AspectOptimisation";
import EventMap from "../../Sections/EventMap";
import MetricBuilder from "../../Sections/MetricBuilder";
import WhatIf from "../../Sections/WhatIf";
import PriceOptimisation from "../../Sections/PriceOptimization";
import TotalSalesMetrics from "../../Sections/TotalSalesMetrics";

type ComponentType = JSX.Element;

interface Tool {
  icon: React.FC;
  label: string;
}

interface ToolsPopupProps {
  onClose: () => void;
  scrollToSection: (area: string, sectionId: number) => void;
  isActive: boolean;
  defaultOpenIds?: number[];
}

const componentsMap: {
  [key: number]: (handleRemove: () => void) => ComponentType;
} = {
  0: (handleRemove) => <TotalOptimization handleRemove={handleRemove} />,
  1: (handleRemove) => <OptimisationDetails handleRemove={handleRemove} />,
  2: (handleRemove) => <AspectOptimisation handleRemove={handleRemove} />,
  3: (handleRemove) => <EventMap handleRemove={handleRemove} />,
  4: (handleRemove) => <MetricBuilder handleRemove={handleRemove} />,
  5: (handleRemove) => <TotalSalesMetrics handleRemove={handleRemove} />,
  6: (handleRemove) => <TotalSalesMetrics handleRemove={handleRemove} />,
  7: (handleRemove) => <WhatIf handleRemove={handleRemove} />,
  8: (handleRemove) => <PriceOptimisation handleRemove={handleRemove} />,
};

function ToolsPopup({
  onClose,
  scrollToSection,
  isActive,
  defaultOpenIds = [],
}: ToolsPopupProps) {
  const {
    addSectionBefore,
    addSectionSide,
    addSectionAfter,
    removeSectionBefore,
    removeSectionSide,
    removeSectionAfter,
  } = useSectionStore((state) => ({
    sectionsBefore: state.sectionsBefore,
    sectionsSide: state.sectionsSide,
    sectionsAfter: state.sectionsAfter,
    addSectionBefore: state.addSectionBefore,
    addSectionSide: state.addSectionSide,
    addSectionAfter: state.addSectionAfter,
    removeSectionBefore: state.removeSectionBefore,
    removeSectionSide: state.removeSectionSide,
    removeSectionAfter: state.removeSectionAfter,
  }));

  const open = (index: number) => {
    const componentFactory = componentsMap[index];

    if (!componentFactory) {
      return;
    }

    if (index === 0 || index === 1) {
      addSectionBefore({
        id: index,
        component: componentFactory(() => removeSectionBefore(index)),
        label: "",
      });
      return "before";
    }
    if (index === 2) {
      addSectionSide({
        id: index,
        component: componentFactory(() => removeSectionSide(index)),
        label: "",
      });
      return "side";
    }
    addSectionAfter({
      id: index,
      component: componentFactory(() => removeSectionAfter(index)),
      label: "",
    });
    return "after";
  };

  const close = (index: number) => {
    const { sectionsBefore, sectionsSide, sectionsAfter } =
      useSectionStore.getState(); // need current state for call on unmount useEffect, otherwise call via hook will be prevstate

    const existingSectionBefore = sectionsBefore.find(
      (section) => section.id === index,
    );
    const existingSectionSide = sectionsSide.find(
      (section) => section.id === index,
    );
    const existingSectionAfter = sectionsAfter.find(
      (section) => section.id === index,
    );

    if (existingSectionBefore) {
      removeSectionBefore(index);
      return "before";
    }
    if (existingSectionSide) {
      removeSectionSide(index);
      return "side";
    }
    if (existingSectionAfter) {
      removeSectionAfter(index);
      return "after";
    }
  };

  const toggle = (index: number) => {
    const area = close(index);

    if (area) return area;

    return open(index);
  };

  const handleClick = (index: number) => {
    const area = toggle(index);

    onClose();

    if (area) {
      setTimeout(() => {
        scrollToSection(area, index);
      }, 0);
    }
  };

  useEffect(() => {
    defaultOpenIds.forEach(open);
    return () => {
      defaultOpenIds.forEach(close);
    };
  }, []);

  return (
    <div className={classNames(styles.popup, isActive && styles.popupActive)}>
      <div className={styles.bg}></div>
      <div className={styles.popupBox}>
        {toolsData.map((tool: Tool, index: number) => (
          <div
            key={index}
            className={styles.popupItem}
            onClick={() => handleClick(index)}
          >
            <tool.icon />
            <span>{tool.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ToolsPopup;
