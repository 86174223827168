import React, { useState, useMemo } from "react";
import MetricItem from "@components/MetricItem";
import CustomPagination from "@components/CustomPagination";
import styles from "./styles.module.scss";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import MetricsArrow from "@assets/icons/metricsArrow";
import { ITEMS_PER_PAGE } from "./constants";
import classNames from "classnames";

interface MetricsListProps {
  searchQuery: string;
  onToggleMetric: (metric: IWidget) => void;
  currentMetrics: IWidget[];
  maxSelectedWidgets: number;
}

const MetricsList: React.FC<MetricsListProps> = ({
  searchQuery,
  onToggleMetric,
  currentMetrics,
  maxSelectedWidgets,
}) => {
  const { availableWidgets } = useDashboardStore((state) => ({
    availableWidgets: state.availableWidgets,
  }));

  const [groupState, setGroupState] = useState<
    Record<string, { isVisible: boolean; isRotated: boolean }>
  >({
    totalWidgets: { isVisible: false, isRotated: false },
    adWidgets: { isVisible: false, isRotated: false },
    organicWidgets: { isVisible: false, isRotated: false },
    otherWidgets: { isVisible: false, isRotated: false },
  });

  const [page, setPage] = useState<number>(1);

  const onPageChange = (newPage: number) => setPage(newPage);

  const filteredItems = useMemo(() => {
    return availableWidgets.filter((metric) =>
      metric.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [availableWidgets, searchQuery]);

  const isMaxSelected = currentMetrics.length >= maxSelectedWidgets;

  const splitWidgetsByGroup = useMemo(() => {
    const totalWidgets = filteredItems.filter(
      (widget) => widget.name === "Sales Total",
    );
    const adWidgets = filteredItems.filter((widget) =>
      widget.name.startsWith("Ads"),
    );
    const organicWidgets = filteredItems.filter((widget) =>
      widget.name.startsWith("Organic"),
    );
    const otherWidgets = filteredItems.filter(
      (widget) =>
        !widget.name.startsWith("Ads") &&
        !widget.name.startsWith("Organic") &&
        widget.name !== "Sales Total",
    );

    return { totalWidgets, adWidgets, organicWidgets, otherWidgets };
  }, [filteredItems]);

  const groups = useMemo(
    () => [
      {
        name: "Totals",
        widgets: splitWidgetsByGroup.totalWidgets,
        key: "totalWidgets",
      },
      { name: "Ads", widgets: splitWidgetsByGroup.adWidgets, key: "adWidgets" },
      {
        name: "Organics",
        widgets: splitWidgetsByGroup.organicWidgets,
        key: "organicWidgets",
      },
      {
        name: "Others",
        widgets: splitWidgetsByGroup.otherWidgets,
        key: "otherWidgets",
      },
    ],
    [splitWidgetsByGroup],
  );

  const currentGroups = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    return groups.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [groups, page]);

  const toggleGroupVisibility = (groupKey: string) => {
    setGroupState((prevState) => ({
      ...prevState,
      [groupKey]: {
        isVisible: !prevState[groupKey]?.isVisible,
        isRotated: !prevState[groupKey]?.isRotated,
      },
    }));
  };

  return (
    <div className={styles.metricsContent}>
      <h5>All metrics</h5>
      <div className={styles.metricList}>
        {currentGroups.map((group) => (
          <div className={styles.metricGroup} key={group.name}>
            <button
              onClick={() => toggleGroupVisibility(group.key)}
              className={classNames(styles.metricButton, {
                [styles.rotated]: groupState[group.key]?.isRotated,
              })}
            >
              {group.name}
              <MetricsArrow />
            </button>

            {groupState[group.key]?.isVisible &&
              group.widgets.map((metric) => {
                const isChecked = currentMetrics.some(
                  (item) => item.id === metric.id,
                );
                return (
                  <div
                    key={metric.id}
                    className={classNames(styles.metricItem, {
                      [styles.dimmed]: isMaxSelected && !isChecked,
                    })}
                  >
                    <MetricItem
                      metric={metric}
                      checked={isChecked}
                      onChange={() => onToggleMetric(metric)}
                      disabled={isMaxSelected && !isChecked}
                    />
                  </div>
                );
              })}
          </div>
        ))}
      </div>
      <CustomPagination
        current={page}
        onChange={onPageChange}
        total={groups.length}
        pageSize={ITEMS_PER_PAGE}
      />
    </div>
  );
};

export default MetricsList;
