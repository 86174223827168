import "./index.scss";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { CustomTooltip } from "./CustomTooltip";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import {
  formatThousands,
  getData,
  lineStyle,
} from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { CampaignsChartProps } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";

const COLORS = [
  "#80C67A",
  "#1BB08D",
  "#FFFFFF",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
];

export const Chart: React.FC<CampaignsChartProps> = ({ checks }) => {
  const [data, setData] = useState<any>();
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData(dateRange, checks);
      const transformedData = data.date.map((date: string, index: number) => ({
        date,
        sales: data.sales[index],
        spend: data.spend[index],
        impressions: data.impressions[index],
        ctr: Number(data.ctr[index]) * 100,
        cvr: Number(data.cvr[index]) * 100,
        cpc: data.cpc[index],
        acos: Number(data.acos[index]) * 100,
        roas: Number(data.roas[index]) * 100,
        unitSolds: data.unitSolds[index],
      }));

      console.log(transformedData);

      setData(transformedData);
    };
    fetchData();
  }, [dateRange, checks]);

  const metricsArray = Object.keys(checks).filter((key) => checks[key]);

  const convertDate = useCallback((timestamp: number) => {
    return format(new Date(timestamp), "d MMM");
  }, []);

  const gradientMetricIndex = useMemo(() => {
    if (
      !Array.isArray(metricsArray) ||
      metricsArray.length === 0 ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return null;
    }

    const firstDataPoint = data[0];
    let maxMetricIndex = 0;

    metricsArray.forEach((metricKey, index) => {
      if (
        firstDataPoint[metricKey] > firstDataPoint[metricsArray[maxMetricIndex]]
      ) {
        maxMetricIndex = index;
      }
    });

    return maxMetricIndex;
  }, [metricsArray, data]);

  return (
    <div className="sales-performance__chart" style={{ zIndex: "0" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 0,
          }}
        >
          <defs>
            {gradientMetricIndex !== null && (
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={COLORS[gradientMetricIndex % COLORS.length]}
                  stopOpacity={0.3}
                />
                <stop
                  offset="100%"
                  stopColor={COLORS[gradientMetricIndex % COLORS.length]}
                  stopOpacity={0}
                />
              </linearGradient>
            )}
          </defs>
          <CartesianGrid stroke="#1F1F20" />
          <XAxis
            dataKey="date"
            tickFormatter={convertDate}
            interval="preserveStartEnd"
            stroke="#7C7C78"
          />

          <Tooltip
            content={({ active, payload, label, coordinate }) => (
              <CustomTooltip
                active={active}
                //@ts-ignore
                payload={payload}
                label={label}
                coordinate={coordinate}
                chartWidth={1302}
              />
            )}
            cursor={{
              fill: "transparent",
              stroke: "white",
              strokeDasharray: 4,
            }}
          />

          <XAxis dataKey="date" tickLine={false} />
          <XAxis dataKey="date" scale="point" xAxisId="lineAxis" hide={true} />
          {/* 1: Impressions */}
          <YAxis
            yAxisId="left1"
            orientation="left"
            stroke="#fff"
            tickFormatter={formatThousands}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            includeHidden
            tickCount={11}
          />
          {/* 2: Sales, Spend */}
          <YAxis
            yAxisId="left2"
            orientation="left"
            stroke="#fff"
            tickFormatter={(tick) => `$${formatThousands(tick)}`}
            hide={!checks.sales && !checks.spend}
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            includeHidden
            tickCount={11}
          />
          {/* 3: NTB Orders, Orders */}
          <YAxis
            yAxisId="left3"
            orientation="left"
            stroke="#6B4DBA"
            tickFormatter={formatThousands}
            hide={!checks.orders && !checks.unitSolds}
            axisLine={false}
            tickLine={false}
            tickCount={11}
          />

          {/* 4: CVR, CTR, ACOS */}
          <YAxis
            yAxisId="right1"
            orientation="right"
            stroke="#FFF"
            tickFormatter={(tick) => `${tick}%`}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            tickCount={11}
          />
          {/* 5: CPC */}
          <YAxis
            yAxisId="right2"
            orientation="right"
            stroke="#FFF"
            tickFormatter={(tick) => `$${tick.toFixed(2)}`}
            hide={!checks.cpc}
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            tickCount={11}
          />
          {/* 6: ROAS */}
          <YAxis
            yAxisId="right3"
            orientation="right"
            stroke="#FFF"
            tickFormatter={(tick) => `${tick.toFixed()}%`}
            hide={!checks.roas}
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            tickCount={11}
          />

          {checks.sales && (
            <Line
              yAxisId="left2"
              type="monotone"
              dataKey="sales"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "sales") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.spend && (
            <Line
              yAxisId="left2"
              type="monotone"
              dataKey="spend"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "spend") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.impressions && (
            <Line
              yAxisId="left1"
              type="monotone"
              dataKey="impressions"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "impressions") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.ctr && (
            <Line
              yAxisId="right1"
              type="monotone"
              dataKey="ctr"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "ctr") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.cvr && (
            <Line
              yAxisId="right1"
              type="monotone"
              dataKey="cvr"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "cvr") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.cpc && (
            <Line
              yAxisId="right2"
              type="monotone"
              dataKey="cpc"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "cpc") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.acos && (
            <Line
              yAxisId="right1"
              type="monotone"
              dataKey="acos"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "acos") %
                    COLORS.length
                ]
              }
            />
          )}
          {checks.roas && (
            <Line
              yAxisId="right3"
              type="monotone"
              dataKey="roas"
              stroke={
                COLORS[
                  metricsArray.findIndex((metric) => metric === "roas") %
                    COLORS.length
                ]
              }
              {...lineStyle}
            />
          )}

          {metricsArray.map((metricKey, index) => {
            if (index === gradientMetricIndex) {
              return (
                <Area
                  key={metricKey}
                  yAxisId="left1"
                  type="monotone"
                  dataKey={metricKey}
                  stroke={COLORS[index % COLORS.length]}
                  fill="url(#colorGradient)"
                  strokeWidth={2}
                  dot={false}
                />
              );
            }
            return (
              <Line
                key={metricKey}
                yAxisId="left1"
                type="monotone"
                dataKey={metricKey}
                stroke={COLORS[index % COLORS.length]}
                strokeWidth={2}
                dot={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
