import { create } from "zustand";

export interface Message {
  id: string;
  text: string | JSX.Element;
  sender: "user" | "bot";
}

export interface ChatState {
  messages: Message[];
  addMessage: (message: Message) => void;
}

export const useChatStore = create<ChatState>((set) => ({
  messages: [],
  addMessage: (message: Message) =>
    set((state) => ({
      messages: [...state.messages, message],
    })),
}));
