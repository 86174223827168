import Chart from "chart.js/auto";
import {
  ActiveElement,
  ChartEvent,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { TooltipData, tooltipPositions } from "./tooltip-types";
import { useMoveDot } from "./useMoveDot";
import { Radar } from "react-chartjs-2";
import { createOptions } from "./chart-data";
import { RadarTooltip } from "./RadarTooltip";
import { labelClickPlugin } from "./label-click-plugin";
import Arrows from "@assets/icons/arrows";
import styles from "./styles.module.scss";
import LongArrow from "@assets/icons/long-arrow";

Chart.register(
  labelClickPlugin,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
);

interface RadarChartProps {
  titles?: string[];
  productOneValues: number[];
  productTwoValues: number[];
  productOneTitles?: string[];
  productTwoTitles?: string[];
  datasets: any[];
  activeIndex: number;
  blockChanging?: boolean;
  onDotClick?: (index: number) => void;
  isDragEnabled?: boolean;
  showTooltipWithArrow?: boolean;
  showTooltip?: boolean;
  width?: number;
  height?: number;
  labelFontSize?: number;
  update?: (productData: number[]) => void;
}

export const RadarChart: React.FC<RadarChartProps> = ({
  datasets,
  activeIndex,
  blockChanging,
  onDotClick,
  isDragEnabled,
  showTooltipWithArrow,
  showTooltip,
  productOneTitles = undefined,
  productTwoTitles = undefined,
  productOneValues,
  productTwoValues,
  width = 400,
  height = 400,
  labelFontSize = 16,
  update,
}) => {
  const compareLabelSet = useMemo(() => {
    return [
      ...new Set([
        ...(Array.isArray(productOneTitles) ? productOneTitles : []),
        ...(Array.isArray(productTwoTitles) ? productTwoTitles : []),
      ]),
    ];
  }, [productOneTitles, productTwoTitles]);
  const maxLengthTitles = useMemo(
    () => Math.max(...compareLabelSet.map((t) => t.length)),
    [compareLabelSet],
  );
  const compareLabelSetWithTheSameLength = useMemo(
    () => compareLabelSet.map((title) => title.padEnd(maxLengthTitles)),
    [compareLabelSet, maxLengthTitles],
  );

  const completeData = (data: number[], labels: string[]) => {
    const diff = labels.length - data.length;

    if (diff > 0) {
      const filler = new Array(diff).fill(0);
      return [...data, ...filler, data[0]];
    } else {
      const firstPoint = data[0];
      if (firstPoint > 0) {
        return [...data, firstPoint];
      } else {
        return [...data, 0];
      }
    }
  };

  const compareDataSet = useMemo(() => {
    return datasets.map((item, index) => {
      if (index === 0 && productOneValues?.length > 0) {
        return {
          ...item,
          data: completeData(productOneValues, compareLabelSet),
        };
      } else if (index === 1 && productTwoValues?.length > 0) {
        return {
          ...item,
          data: completeData(productTwoValues, compareLabelSet),
        };
      }
      return item;
    });
  }, [datasets, productOneValues, productTwoValues, compareLabelSet]);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipData, setTooltipData] = useState<TooltipData>(null);
  const options = useMemo(
    () => createOptions({ labelFontSize }),
    [labelFontSize],
  );

  const chartRef = useRef<Chart<"radar", number[], string>>(null);
  useMoveDot(chartRef, isDragEnabled, activeIndex, update);

  useEffect(() => {
    if (chartRef.current && showTooltipWithArrow) {
      const chart = chartRef.current;
      const meta = chart.getDatasetMeta(0);
      const element = meta.data[activeIndex];

      if (element) {
        chart.tooltip.setActiveElements(
          [{ datasetIndex: 0, index: activeIndex }],
          { x: element.x, y: element.y },
        );
        chart.update();
      }
    }
  }, [showTooltipWithArrow, activeIndex]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;

      chart.data.datasets.forEach((dataset: any) => {
        const borderColor = dataset.borderColor;

        dataset.pointBackgroundColor = dataset.data.map(
          (_: number, index: number) =>
            index === activeIndex ? borderColor : "transparent",
        );
        dataset.pointBorderColor = dataset.data.map(
          (_: number, index: number) =>
            index === activeIndex ? borderColor : "transparent",
        );
        dataset.pointRadius = dataset.data.map((_: number, index: number) =>
          index === activeIndex ? 6 : 0,
        );
        dataset.pointHoverRadius = dataset.data.map(
          (_: number, index: number) => (index === activeIndex ? 12 : 0),
        );
      });

      chart.update();
    }
  }, [activeIndex, datasets]);

  const handleChartClick = (_: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const firstElement = elements[0];
      const index = firstElement.index;

      if (onDotClick) {
        onDotClick(index);
      }
    }
  };

  const handleLabelClick = (index: number) => {
    if (onDotClick) {
      onDotClick(index);
    }
  };

  return (
    <div style={{ width, height }} className={styles.radarChart}>
      {showTooltipWithArrow && (
        <>
          <div className={styles.tooltipWithArrow}>
            <Arrows /> Drag selected vertex to see our predictions
          </div>
          <div className={styles.arrow}>
            <LongArrow />
          </div>
        </>
      )}
      <Radar
        ref={chartRef}
        options={{
          ...options,
          onClick: handleChartClick,
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              external: ({ tooltip }) => {
                if (!blockChanging || !isDragEnabled) {
                  const needOpen = tooltip.opacity === 1;
                  if (!needOpen) {
                    setIsTooltipOpen(false);
                    return;
                  }
                  const caretNotMove =
                    tooltipData &&
                    tooltip.caretY === tooltipData.caretY &&
                    tooltip.caretX === tooltipData.caretX;
                  if (isTooltipOpen && caretNotMove) return;
                  setTooltipData({
                    dataPoints: tooltip.dataPoints,
                    caretY: tooltip.caretY,
                    caretX: tooltip.caretX,
                    title: tooltip.title[0] ?? "",
                    index: tooltip.dataPoints[0]?.dataIndex ?? -1,
                  });
                  setIsTooltipOpen(true);
                }
              },
            },
            onLabelClick: handleLabelClick,
          },
          interaction: {
            mode: "index",
            intersect: true,
          },

          events: blockChanging
            ? []
            : ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
        }}
        data={{
          labels: compareLabelSetWithTheSameLength.length
            ? compareLabelSetWithTheSameLength
            : [
                "Effectiveness",
                "Ingredients",
                "Price",
                "Value",
                "Side effects",
                "Taste",
                "Brand",
              ],
          datasets: compareDataSet,
        }}
      />
      {showTooltip && tooltipData && (
        <RadarTooltip
          data={tooltipData}
          isOpen={isTooltipOpen}
          style={tooltipPositions[tooltipData.index]}
        />
      )}
    </div>
  );
};
