import { ExpandableTable } from "@components/ExpandebleTable";
import { formatData, performanceColumns, performanceData } from "../../mock";
import { useState } from "react";

export const PerformanceTable = () => {
  const [tooltipPerformanceTitle, setTooltipPerformanceTitle] = useState("");

  const handlePerformanceTooltip = (title: string) => {
    setTooltipPerformanceTitle(title);
  };

  return (
    <ExpandableTable
      columns={performanceColumns}
      data={formatData(
        performanceData,
        handlePerformanceTooltip,
        tooltipPerformanceTitle,
      )}
    />
  );
};
