import { ArrowDown, ArrowUp } from "lucide-react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";

interface MetricCardProps {
  metric: IWidget;
}

export const MetricCard = ({ metric }: MetricCardProps) => {
  const profit = metric.difference.startsWith("+");
  const metricValue = metric.originalValue;
  const maxMetricValue = metricValue * 1.2; // for test only, need change later
  const placeholderValue = maxMetricValue - metricValue;
  const color = profit ? "#80C67A" : "#FE5858";
  const pieWidth = 190;
  const strokeWidth = 30;

  return (
    <div className={styles.metricCard}>
      <div className={styles.metricHeader}>{metric.name}</div>
      <div className={styles.metricBody}>
        <PieChart
          width={pieWidth}
          height={pieWidth / 2}
          className={styles.metricPie}
        >
          <Pie
            data={[{ value: 100 }]} // any value > 0, the value is not important
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={pieWidth / 2 - strokeWidth}
            outerRadius={pieWidth / 2}
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
            fill="#2E2E2E"
            stroke="none"
          ></Pie>
          <Pie
            data={[{ value: metricValue }, { value: placeholderValue }]}
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={pieWidth / 2 - strokeWidth}
            outerRadius={pieWidth / 2}
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
          >
            <Cell fill={color} stroke="none" />
            <Cell fill="none" stroke="none" />
          </Pie>
        </PieChart>
        <div className={styles.metricValuesBox}>
          <div className={styles.metricChange} style={{ color }}>
            {metric.difference}%
            {profit ? <ArrowUp size={12} /> : <ArrowDown size={12} />}
          </div>
          <h4 className={styles.metricValue}>${metricValue.toFixed(2)}</h4>
        </div>
        <div className={styles.metricCircle}>
          <svg viewBox="0 0 100 52" version="1.1">
            <circle
              cx="50px"
              cy="50px"
              r="48"
              stroke="#C8A857"
              strokeWidth="2"
              strokeDasharray="0 10"
              strokeLinecap="round"
              fill="transparent"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
