import { SalesPerformanceApi } from "@services/sales-performance/sales-performance.api";
import { format } from "date-fns";

export type Checks = {
  sales: boolean;
  spend: boolean;
  impressions: boolean;
  ctr: boolean;
  cvr: boolean;
  cpc: boolean;
  acos: boolean;
  roas: boolean;
  orders: boolean;
  unitSolds: boolean;
  clicks: boolean;
};

export const lineStyle = {
  strokeWidth: 2,
  dot: false,
  activeDot: false,
  xAxisId: "lineAxis",
};

export const formatThousands = (tick) => {
  if (tick < 1000) return tick;
  return `${(tick / 1000).toFixed(1)}k`;
};

export const getData = async (
  dateRange: {
    startDate: Date;
    endDate: Date;
  },
  checks?: Checks,
) => {
  const { startDate, endDate } = dateRange;
  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];

  const {
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    unitSolds,
    clicks,
  } = checks;

  const salesPerformanceApi = new SalesPerformanceApi();

  const promises = [
    sales && salesPerformanceApi.getSales(startDateString, endDateString),
    spend && salesPerformanceApi.getSpend(startDateString, endDateString),
    impressions &&
      salesPerformanceApi.getImpressions(startDateString, endDateString),
    ctr && salesPerformanceApi.getCtr(startDateString, endDateString),
    cvr && salesPerformanceApi.getCvr(startDateString, endDateString),
    cpc && salesPerformanceApi.getCpc(startDateString, endDateString),
    acos && salesPerformanceApi.getAcos(startDateString, endDateString),
    roas && salesPerformanceApi.getRoas(startDateString, endDateString),
    unitSolds &&
      salesPerformanceApi.getUnitsSold(startDateString, endDateString),
    clicks && salesPerformanceApi.getClicks(startDateString, endDateString),
  ].filter(Boolean);

  const [
    salesData,
    spendData,
    impressionsData,
    ctrData,
    cvrData,
    cpcData,
    acosData,
    roasData,
    unitSoldsData,
    clicksData,
  ] = await Promise.all(promises);

  return {
    date: generateDays(startDate, endDate),
    sales: salesData,
    spend: spendData,
    impressions: impressionsData,
    ctr: ctrData,
    cvr: cvrData,
    cpc: cpcData,
    acos: acosData,
    roas: roasData,
    unitSolds: unitSoldsData,
    clicks: clicksData,
  };
};

const generateDays = (startDate, endDate) => {
  const days = (endDate - startDate) / (1000 * 60 * 60 * 24);
  const data = [];
  for (let i = 0; i < days; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);

    data.push(format(currentDate, "d MMM"));
  }
  return data;
};
