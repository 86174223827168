import { Chart } from "chart.js";
import { useEffect } from "react";
import { tooltipArrayState } from "./tooltip-types";
import { MAX_RATING, MIN_AVAILABLE_RATING } from "./chart-data";

export const useMoveDot = (
  chartRef: React.RefObject<Chart<"radar", number[], string>>,
  isDragEnabled: boolean,
  activeIndex = -1,
  update?: (productData: number[]) => void,
) => {
  useEffect(() => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    const canvas = chart.canvas;

    const lengthInPixelToLine = 60;
    const ratio = lengthInPixelToLine / MAX_RATING;

    canvas.style.cursor = isDragEnabled ? "pointer" : "default";

    const handleMouseDown = (e: MouseEvent) => {
      const productData = chart.data.datasets[0]?.data;

      if (!productData) return;

      if (!isDragEnabled) return;

      if (chart.tooltip?.opacity === 0) return;

      const index = chart.tooltip?.dataPoints[0]?.dataIndex ?? -1;
      if (!(activeIndex === -1 || activeIndex === index)) return;

      const activeState = tooltipArrayState[index];
      if (!activeState) return;

      const startX = e.clientX;
      const startY = e.clientY;
      const startData = productData[index];

      canvas.style.cursor = "grabbing";

      const handleMouseMove = (e: MouseEvent) => {
        const offsetX = (startX - e.clientX) / ratio;
        const offsetY = (startY - e.clientY) / ratio;
        const offset =
          (activeState.direction === "x" ? offsetX : offsetY) *
          (activeState.reverse ? -1 : 1);
        const newData = startData + offset;

        productData[index] = Number(
          (newData < MIN_AVAILABLE_RATING
            ? MIN_AVAILABLE_RATING
            : newData > 5
              ? 5
              : newData
          ).toFixed(2),
        );
        chart.update();
        update && update(productData);
      };

      const handleMouseUp = () => {
        canvas.removeEventListener("mousemove", handleMouseMove);
        canvas.style.cursor = "default";
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp, { once: true });
    };

    canvas.addEventListener("dragstart", () => false);
    canvas.addEventListener("mousedown", handleMouseDown);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
    };
  }, [chartRef, isDragEnabled, activeIndex]);
};
