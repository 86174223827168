import React from "react";
import styles from "./styles.module.scss";
import Arrow from "@assets/icons/Arrow";
import Star from "@assets/icons/Star";

interface SwiperNavigationProps {
  onNext: () => void;
  onPrev: () => void;
  headerText: string;
  improvementValue?: string;
  showText?: boolean;
}

const SwiperNavigation: React.FC<SwiperNavigationProps> = ({
  onNext,
  onPrev,
  headerText,
  improvementValue = "0.3",
  showText = false,
}) => {
  return (
    <div className={styles.swiperNavigation}>
      <div className={styles.buttonContainer}>
        <button className={styles.backButton} onClick={onPrev}>
          <Arrow />
        </button>
        <button className={styles.forwardButton} onClick={onNext}>
          <Arrow />
        </button>
      </div>
      <h3 className={styles.headerText}>{headerText}</h3>
      {showText && (
        <p className={styles.improvementText}>
          Improving this aspect you will improve review score by{" "}
          {improvementValue} <Star />
        </p>
      )}
    </div>
  );
};

export default SwiperNavigation;
