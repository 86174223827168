import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
} from "recharts";
import { Checks, formatThousands, getData, lineStyle } from "./utils";
import { CustomTooltip } from "./Tooltip";
import { useEffect, useState } from "react";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";

export interface CampaignsChartProps {
  checks: Checks;
}

export const CampaignsChart = ({ checks }: CampaignsChartProps) => {
  const [data, setData] = useState<any>();
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData(dateRange, checks);
      const transformedData = data.date.map((date: string, index: number) => ({
        date,
        sales: data.sales[index],
        spend: data.spend[index],
        impressions: data.impressions[index],
        ctr: Number(data.ctr[index]) * 100,
        cvr: Number(data.cvr[index]) * 100,
        cpc: data.cpc[index],
        acos: Number(data.acos[index]) * 100,
        roas: Number(data.roas[index]) * 100,
        unitSolds: data.unitSolds[index],
      }));

      setData(transformedData);
    };
    fetchData();
  }, [dateRange, checks]);

  return (
    <div>
      <ComposedChart width={1302} height={423} data={data}>
        <CartesianGrid stroke="#1F1F20" />
        <Tooltip
          content={({ active, payload, label, coordinate }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
              coordinate={coordinate}
              chartWidth={1302}
            />
          )}
          cursor={{
            fill: "transparent",
            stroke: "white",
            strokeDasharray: 4,
          }}
        />

        <XAxis dataKey="date" tickLine={false} />

        <XAxis dataKey="date" scale="point" xAxisId="lineAxis" hide={true} />

        {/* Orders, NTB Orders */}
        {checks.orders && (
          <Bar
            yAxisId="left3"
            dataKey="orders"
            fill="#5295E0"
            barSize={!checks.unitSolds ? "40" : undefined}
          />
        )}
        {checks.unitSolds && (
          <Bar
            yAxisId="left3"
            dataKey="unitSolds"
            fill="#255FA0"
            barSize={!checks.orders ? "40" : undefined}
          />
        )}

        {/* 1: Impressions */}
        <YAxis
          yAxisId="left1"
          orientation="left"
          stroke="#6B4DBA"
          tickFormatter={formatThousands}
          tickLine={false}
          axisLine={{
            stroke: "#fff",
          }}
          domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
          includeHidden
          tickCount={11}
        />

        {/* 2: Sales, Spend */}
        <YAxis
          yAxisId="left2"
          orientation="left"
          stroke="rgb(128, 198, 122)"
          tickFormatter={(tick) => `$${formatThousands(tick)}`}
          hide={!checks.sales && !checks.spend}
          axisLine={false}
          tickLine={false}
          domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
          includeHidden
          tickCount={11}
        />

        {/* 3: NTB Orders, Orders */}
        <YAxis
          yAxisId="left3"
          orientation="left"
          stroke="#255FA0"
          tickFormatter={formatThousands}
          hide={!checks.orders && !checks.unitSolds}
          axisLine={false}
          tickLine={false}
          tickCount={11}
        />

        {/* 4: CVR, CTR, ACOS */}
        <YAxis
          yAxisId="right1"
          orientation="right"
          stroke="#FFF"
          tickFormatter={(tick) => `${tick}%`}
          tickLine={false}
          domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
          tickCount={11}
        />

        {/* 5: CPC */}
        <YAxis
          yAxisId="right2"
          orientation="right"
          stroke="#E6FF4B"
          tickFormatter={(tick) => `$${tick.toFixed(2)}`}
          hide={!checks.cpc}
          axisLine={false}
          tickLine={false}
          domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
          tickCount={11}
        />

        {/* 6: ROAS */}
        <YAxis
          yAxisId="right3"
          orientation="right"
          stroke="#F19867"
          tickFormatter={(tick) => `${tick.toFixed()}%`}
          hide={!checks.roas}
          axisLine={false}
          tickLine={false}
          domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
          tickCount={11}
        />

        {checks.sales && (
          <Line
            yAxisId="left2"
            type="monotone"
            dataKey="sales"
            stroke="#80C67A"
            {...lineStyle}
          />
        )}
        {checks.spend && (
          <Line
            yAxisId="left2"
            type="monotone"
            dataKey="spend"
            stroke="#FE5858"
            {...lineStyle}
          />
        )}
        {checks.impressions && (
          <Line
            yAxisId="left1"
            type="monotone"
            dataKey="impressions"
            stroke="#6B4DBA"
            {...lineStyle}
          />
        )}
        {checks.ctr && (
          <Line
            yAxisId="right1"
            type="monotone"
            dataKey="ctr"
            stroke="#1BB08D"
            {...lineStyle}
          />
        )}
        {checks.cvr && (
          <Line
            yAxisId="right1"
            type="monotone"
            dataKey="cvr"
            stroke="#C8A857"
            {...lineStyle}
          />
        )}
        {checks.cpc && (
          <Line
            yAxisId="right2"
            type="monotone"
            dataKey="cpc"
            stroke="#E6FF4B"
            {...lineStyle}
          />
        )}
        {checks.acos && (
          <Line
            yAxisId="right1"
            type="monotone"
            dataKey="acos"
            stroke="#E667F1"
            {...lineStyle}
          />
        )}
        {checks.roas && (
          <Line
            yAxisId="right3"
            type="monotone"
            dataKey="roas"
            stroke="#F19867"
            {...lineStyle}
          />
        )}
      </ComposedChart>
    </div>
  );
};
