import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSectionStore } from "src/store/croModule.state";
import styles from "./styles.module.scss";
import ProductDetailPage from "../../../Product";

function Sections({ sectionRefs }) {
  const sectionsBefore = useSectionStore((state) => state.sectionsBefore);
  const sectionsSide = useSectionStore((state) => state.sectionsSide);
  const sectionsAfter = useSectionStore((state) => state.sectionsAfter);
  const reorderSections = useSectionStore((state) => state.reorderSections);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    reorderSections(result.source, result.destination);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="sectionsBefore">
        {(provided) => (
          <div
            className={styles.section}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sectionsBefore.map((section, index) => (
              <Draggable
                key={section.id}
                draggableId={`before-${section.id}`}
                index={index}
              >
                {(provided) => (
                  <div
                    className={styles.container}
                    ref={(el) => {
                      provided.innerRef(el);
                      if (el) {
                        sectionRefs.current[`before-${section.id}`] = el;
                      }
                    }}
                    {...provided.draggableProps}
                  >
                    {React.cloneElement(
                      section.component as React.ReactElement<any>,
                      {
                        id: section.id,
                        provided,
                      },
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div className={styles.productDetailContainer}>
        <ProductDetailPage
          sideSlot={
            <Droppable droppableId="sectionsSide">
              {(provided) => (
                <div
                  className={styles.section}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sectionsSide.map((section, index) => (
                    <Draggable
                      key={section.id}
                      draggableId={`side-${section.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className={styles.container}
                          ref={(el) => {
                            provided.innerRef(el);
                            if (el) {
                              sectionRefs.current[`side-${section.id}`] = el;
                            }
                          }}
                          {...provided.draggableProps}
                        >
                          {React.cloneElement(
                            section.component as React.ReactElement<any>,
                            {
                              id: section.id,
                              provided,
                            },
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          }
        />
      </div>

      <Droppable droppableId="sectionsAfter">
        {(provided) => (
          <div
            className={styles.section}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sectionsAfter.map((section, index) => (
              <Draggable
                key={section.id}
                draggableId={`after-${section.id}`}
                index={index}
              >
                {(provided) => (
                  <div
                    className={styles.container}
                    ref={(el) => {
                      provided.innerRef(el);
                      if (el) {
                        sectionRefs.current[`after-${section.id}`] = el;
                      }
                    }}
                    {...provided.draggableProps}
                  >
                    {React.cloneElement(
                      section.component as React.ReactElement<any>,
                      {
                        id: section.id,
                        provided,
                      },
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Sections;
