import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { mockTooltipData } from "@components/Tooltip/tooltip.const";
import { Graph } from "../chart.types";
import { SunkeyChart } from "../chart.class";
import { Tooltip, TooltipGrapgicsData } from "@components/Tooltip/tooltip";
import { TooltipData } from "@components/TreeChart/chart.types";

interface HorizontalChartProps {
  data: Graph;
  isAdType?: boolean;
}

export const HorizontalChart: React.FC<HorizontalChartProps> = ({
  data,
  isAdType,
}) => {
  const sunkeyChartInstance = new SunkeyChart();
  const [chartData, setChartData] = useState<Graph>(
    sunkeyChartInstance.formatData(data),
  );
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
  const [tooltipPositionY, setTooltipPositionY] = useState<number | null>(null);
  const [tooltipWithButton, setTooltipWithButton] = useState(false);
  const [tooltipWithImg, setTooltipWithImg] = useState(false);
  const [tooltipGraph, setTooltipGraph] = useState<TooltipGrapgicsData>();

  useEffect(() => {
    setChartData(sunkeyChartInstance.formatData(data));
  }, [data]);

  const clearStyles = () => {
    setTooltipData(null);
    setTooltipPositionY(null);
    setChartData(sunkeyChartInstance.clearOpacity(chartData));
  };

  const onChartClick = (params: any) => {
    setTooltipWithButton(false);
    setTooltipWithImg(false);
    setTooltipGraph(undefined);
    const tooltipName = params.name.replace(">", "--");
    setTooltipData({
      name: tooltipName,
      ...mockTooltipData,
    });
    setTooltipPositionY(params.event.offsetY - 250);

    if (tooltipName.includes("--")) {
      setTooltipWithButton(true);
    }

    if (tooltipName.startsWith("Nyl") && isAdType) {
      setTooltipWithImg(true);
    }

    const { data, tooltipData } = sunkeyChartInstance.highlightNode(
      tooltipName,
      chartData,
    );

    setChartData(data);

    if (tooltipData) {
      setTooltipGraph(tooltipData);
    }

    return;
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ReactEcharts
        option={sunkeyChartInstance.getOption(chartData)}
        style={{ height: "720px", width: "100%" }}
        onEvents={{ click: onChartClick }}
      />
      {tooltipData && tooltipPositionY && (
        <Tooltip
          data={tooltipData}
          clearStyles={clearStyles}
          position={tooltipPositionY}
          withImg={tooltipWithImg}
          withButton={tooltipWithButton}
          graphics={tooltipGraph}
        />
      )}
    </div>
  );
};
