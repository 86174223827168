import { Product } from "@services/amazon/products/types";
import { create } from "zustand";

interface IProductState {
  selectedProduct: Product | null;
  selectedProductToCompare: Product[];
  setSelectedProduct: (product: Product) => void;
  setSelectedProductToCompare: (products: Product[]) => void;
  selectedProductToCompareMultiselect: (product: Product) => void;
}

export const useProductStore = create<IProductState>((set) => ({
  selectedProduct: null,
  selectedProductToCompare: [],
  setSelectedProduct: (product) => set({ selectedProduct: product }),
  setSelectedProductToCompare: (products) =>
    set((state) => ({
      selectedProductToCompare: Array.isArray(products) ? products : [products],
    })),

  selectedProductToCompareMultiselect: (product) =>
    set((state) => ({
      selectedProductToCompare: [...state.selectedProductToCompare, product],
    })),
}));
