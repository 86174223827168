import React, { useState } from "react";
import styles from "../styles.module.scss";
import ProductCode from "@components/ProductCode";
import ProductInput from "@components/ProductInput";
import { Product } from "@services/amazon/products/types";
import { OverviewType } from "@pages/Overview";

interface MockDataType {
  type: string;
  name: string;
  price: string;
  rating: number;
  reviews: Record<string, number>;
  image: string;
  code: string;
}

interface ProductCardProps {
  productTwo?: boolean;
  product: Product | null;
  label: string;
  selectType: "your" | "competitor";
  mockData: MockDataType;
  type: OverviewType;
  showInput?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productTwo = false,
  product,
  label,
  selectType,
  mockData,
  type,
  showInput = true,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const spiderData = product?.[0]?.spiderDots;

  const productPrice = spiderData?.price ?? mockData.price;
  const productImage = spiderData?.picture_url ?? mockData.image;
  const productAsin = spiderData?.asin ?? product?.asin ?? mockData.code;
  const getStarReviews = (reviews: Record<string, number>) => {
    const starRatings = [
      { label: "5 STAR", value: reviews["5_star"] },
      { label: "4 STAR", value: reviews["4_star"] },
      { label: "3 STAR", value: reviews["3_star"] },
      { label: "2 STAR", value: reviews["2_star"] },
      { label: "1 STAR", value: reviews["1_star"] },
    ];
    return starRatings;
  };

  const rating = product?.rating ?? mockData.rating;
  const reviews = mockData.reviews;
  const productsToRender = product ? [product] : [mockData];
  const limitedProductsToRender = productsToRender.slice(0, 4);

  return (
    <div className={`${styles.product}`}>
      {selectType === "your" ? (
        <>
          <div className={styles.productCode}>
            <ProductInput
              label={label}
              selectType={selectType}
              productTwo={productTwo}
              type={type}
              onProductSelect={selectedProduct}
            />
            <ProductCode code={product?.asin || mockData.code} />
          </div>
          <div
            className={`${styles.imgRateContainer} ${styles["product-borderedOne"]}`}
          >
            <div className={styles.image}>
              <img
                src={
                  product?.image_link ?? product?.picture_url ?? mockData.image
                }
                alt={product?.title ?? mockData.name}
              />
            </div>
            <div className={styles.details}>
              <span className={styles.price}>
                ${product?.price ?? mockData.price}
              </span>
              <div className={styles.rating}>
                {[...Array(5)].map((_star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <span key={index} className={styles.star}>
                      {ratingValue <= rating ? "★" : "☆"}
                    </span>
                  );
                })}
                <span className={styles.ratingValue}>{rating}.0</span>
              </div>
              <ul className={styles.reviews}>
                {getStarReviews(reviews).map((review, index) => (
                  <li key={index} className={styles.reviewItem}>
                    <span className={styles.reviewLabel}>{review.label}</span>
                    <div className={styles.reviewBar}>
                      <div
                        className={styles.reviewFill}
                        style={{ width: `${review.value}%` }}
                      ></div>
                    </div>
                    <span className={styles.reviewPercentage}>
                      {review.value}%
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${styles.competitorStack}`}>
            {showInput && (
              <div className={styles.productCode}>
                <ProductInput
                  label={label}
                  selectType={selectType}
                  productTwo={productTwo}
                  type={type}
                  onProductSelect={selectedProduct}
                />
                <ProductCode code={product?.asin || mockData.code} />
              </div>
            )}

            {limitedProductsToRender.map((item, index) => (
              <div
                key={index}
                className={`${styles.competitorProduct} ${styles["product-bordered"]}`}
              >
                <div className={styles.productVisualRow}>
                  <img
                    src={
                      item?.image_link ??
                      item?.picture_url ??
                      productImage ??
                      mockData.image
                    }
                    className={styles.competitorImage}
                  />
                  <div className={styles.productInfo}>
                    <div className={styles.productBetween}>
                      <div className={styles.priceAndCode}>
                        <span className={styles.price}>
                          ${item.price ?? productPrice ?? mockData.price}
                        </span>
                        <ProductCode
                          code={item.asin || productAsin || mockData.code}
                        />
                      </div>
                      <div className={styles.rating}>
                        {[...Array(5)].map((_star, index) => {
                          const ratingValue = index + 1;
                          return (
                            <span key={index} className={styles.star}>
                              {ratingValue <= rating ? "★" : "☆"}
                            </span>
                          );
                        })}
                        <span className={styles.ratingValue}>{rating}.0</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductCard;
