import styles from "./styles.module.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  LabelList,
  Tooltip,
} from "recharts";
import TopSectionIcons from "../components/TopSectionIcons";
import SectionContainer from "../components/SectionContainer";
import { data } from "./mock";
import { DraggableProvided } from "react-beautiful-dnd";

const CustomizedAxisTick = ({ x, y, payload }) => {
  const lines = payload.value
    .split(" ")
    .map((word, index, wordsArray) => {
      let currentLine = word;
      for (let i = index + 1; i < wordsArray.length; i++) {
        if (currentLine.length + wordsArray[i].length < 10) {
          currentLine += ` ${wordsArray[i]}`;
          wordsArray[i] = "";
        } else {
          break;
        }
      }
      return currentLine;
    })
    .filter((line) => line !== "");

  return (
    <g transform={`translate(${x},${y + 10})`}>
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * 12}
          textAnchor="middle"
          fill="#fff"
          fontSize="10"
        >
          {line}
        </text>
      ))}
    </g>
  );
};
interface WhatIfProps {
  handleRemove: () => void;
  provided?: DraggableProvided;
}

const WhatIf = ({ handleRemove, provided }: WhatIfProps) => {
  return (
    <SectionContainer>
      <TopSectionIcons
        handleRemove={handleRemove}
        isContentClosed={false}
        provided={provided}
      />
      <div className={styles.whatIfContainer}>
        <h3 className={styles.title}>What-If Analysis</h3>
        <ResponsiveContainer width={627} height={400}>
          <BarChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 80 }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey="name"
              tick={<CustomizedAxisTick x={0} y={0} payload={{ value: "" }} />}
              interval={0}
            />
            <YAxis
              tickFormatter={(value) => `${value / 1000000}M`}
              tick={{ fill: "white" }}
            />
            <Tooltip
              formatter={(value) => `${(Number(value) / 1000000).toFixed(2)}M`}
              cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
            />
            <Bar dataKey="pv" fill="transparent" stackId="a" />
            <Bar dataKey="uv" stackId="a">
              {data.map((entry, index) => (
                <Cell key={`cell-value-${index}`} fill={entry.color} />
              ))}
              <LabelList
                dataKey="uv"
                position="top"
                formatter={(value) => `${Math.abs(value) / 1000000}M`}
                fill="white"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </SectionContainer>
  );
};

export default WhatIf;
