import { Api } from "src/utils/api";
import { INSTATHINK_API_BASE_URL } from "./chat.constant";

export class ChatApi extends Api {
  constructor() {
    super(INSTATHINK_API_BASE_URL);
  }

  public sendMessage = async (
    question: string,
  ): Promise<{
    body: string;
  }> => {
    return await this.post("", {
      question,
      urls: ["https://www.amazon.com/gp/help/customer/display.html"],
      chunkSize: 1024,
      chunkOverlap: 50,
    });
  };
}
